import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    drawerOpen: {
        bol: false,
        open: false,
      },
      certificateCount:{
        hotWork:0,
        confinedSpace:0,
        radiation:0,
        excavation:0,
        isolation:0,
        safe:0,
        lifting:0
      },
      certificateName:[],
      labour:[],
      excavationAttachment:[],
}

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    drawer: (state,actions) => {
      state.drawerOpen = actions.payload
    },
    certificateName:(state,actions)=>{
       state.certificateName=actions.payload
    },
    certificateCountAdd:(state,actions)=>{
      state.certificateCount[actions.payload.name]=state.certificateCount[actions.payload.name]+1
    },
    createCertificateCount:(state,actions)=>{
      state.certificateCount={
        hotWork:actions.payload.hotWork!==undefined&&actions.payload.hotWork!==0?actions.payload.hotWork:state.certificateCount.hotWork,
        confinedSpace:actions.payload.confinedSpace!==undefined&&actions.payload.confinedSpace!==0?actions.payload.confinedSpace:state.certificateCount.confinedSpace,
        radiation:actions.payload.radiation!==undefined&&actions.payload.radiation!==0?actions.payload.radiation:state.certificateCount.radiation,
        excavation:actions.payload.excavation!==undefined&&actions.payload.excavation!==0?actions.payload.excavation:state.certificateCount.excavation,
        isolation:actions.payload.isolation!==undefined&&actions.payload.isolation!==0?actions.payload.isolation:state.certificateCount.isolation,
        safe:actions.payload.safe!==undefined&&actions.payload.safe!==0?actions.payload.safe:state.certificateCount.safe,
        lifting:actions.payload.lifting!==undefined&&actions.payload.lifting!==0?actions.payload.lifting:state.certificateCount.lifting,
      }
    },
    labour:(state,actions)=>{
      state.labour=actions.payload
   },
   excavationAttach:(state,actions)=>{
    state.excavationAttachment=[...state.excavationAttachment,actions.payload]
 },


   
  },
})

 export const { drawer,certificateName,createCertificateCount,labour,excavationAttach } = dataSlice.actions

export const dataReducers= dataSlice.reducer