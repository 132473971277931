import { Button, Divider, Grid2, Paper, Typography,TextField,Select,InputLabel,Box, IconButton, Dialog } from '@mui/material'
import React, { useState } from 'react'
import { commonCss } from '../../../StyleConfig/CommonCss'
import { Add, ArrowBack, DeleteOutline, FileUploadOutlined } from '@mui/icons-material'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {  EditOutlined } from '@mui/icons-material'
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { useSelector } from 'react-redux';
import NormalTable from '../../../Component/NormalTable';
import LabourSelect from '../LabourSelect';
import PSImageRiskAssessment from "../../../Images/PSImageRiskAssessment.png"
import NormalTableWithRadius from '../../../Component/NormalTableWithRadius'

const RiskAssessment = ({handleCloseClick}) => {
    
    const [labourList,setLabourList]=useState([])
    
    const [labour,setLabour]=useState("")
    const [editLabour,setEditLabour]=useState({bol:false,i:null})
    const [hazardData,setHazardData]=useState([])
    const [getHazard,setHazard]=useState({
      identification:"",
      impact:"",
      control:"",
      additionalContorl:"",
      thoseAtRisk:"",
      
  })
  const [editHazard,setEditHazard]=useState({bol:false,i:null})

    
    const labourColumns= [
       
        {
          Header:"Labour Name",
          accessor: "name",
          // disableFilters: true,
        },
        {
          accessor:"id",
          Cell: ({ cell }) => {
            return (
              <Box sx={{ whiteSpace: "nowrap",display:"flex",justifyContent:"flex-end",width:"100%",alignItems:'center' }}>
              
                <IconButton onClick={()=>{
                  setEditLabour({bol:true,i:cell.row.id})
                  setLabour(cell.row.original.name)
                }} >
                  <EditOutlined sx={{color:commonCss.red}}/>
                </IconButton>
               
               
              </Box>
            );
          },
        }
        
      ]

      const listOfInstruction=[
        "Electricity", 
        "Noise", 
        "Lasers" ,
        "Radiation", 
        "Vacuum ",
        "Legionella", 
        "Weil's Disease", 
        "Other Biological (Specify)", 
        "Psychological Hazards", 
        "High Pressure", 
        "Explosion",
        "Fire Hazard", 
        "Gas Vapor Release", 
        "Toxic Chemicals" ,
        "Flammable Substances ",
         "Hazard to eyes", 
         "Hand Tools ",
         "Dangerous Machinery ",
         "Extreme of Temperature", 
         "Confined Spaces", 
         "Working environment ",
         "Manual Handling ",
         "Gravity (Height or above excavations)", 
         "Moving Vehicles" ,
         "Cleaning Devices", 
         "Slips, Trip, and Falls" ,
         "Environmental Effects", 
         "Other",
      ]

      const handleHazard=()=>{
        if(editHazard.bol){
         let updateHazard=[...hazardData]
         updateHazard[editHazard.i]=getHazard
         setHazardData(updateHazard)
         setEditHazard({bol:false,i:null})
         setHazard({
          identification:"",
          impact:"",
          control:"",
          additionalContorl:"",
          thoseAtRisk:"",
        })
        }else{
            setHazardData([...hazardData,getHazard])
           setHazard({
            identification:"",
            impact:"",
            control:"",
            additionalContorl:"",
            thoseAtRisk:"",
        })
        
        }
        
    }

    const hazardColumns=[
      {
        Header:"Hazards Identification",
        accessor:"identification"
      },
      
      {
          Header:"Risk Assessment (Impact)",
          accessor:"impact"
        },
        {
          Header:"Risk Control",
          accessor:"control"
        },
        {
            Header:"Additional Control",
            accessor:"additionalContorl"
          },
          {
            Header:"Those at Risk",
            accessor:"thoseAtRisk"
          },
      {
        accessor:"id",
        Cell: ({ cell }) => {
          return (
            <Box sx={{ whiteSpace: "nowrap",display:"flex",justifyContent:"center",width:"100%",alignItems:'center' }}>
            
              <IconButton 
              onClick={()=>{
                setEditHazard({bol:true,i:cell.row.id})
                setHazard(cell.row.original)
              }}
              >
                <EditOutlined sx={{color:commonCss.red}}/>
                
              </IconButton>
             
            </Box>
          );
        },
      }
    ]

    const handleAccessor=()=>{
       if(editLabour.bol){
        let updateLabour=[...labourList] 
        updateLabour[editLabour.i]={name:labour}
        setLabourList(updateLabour)
        setEditLabour({bol:false,i:null})
        setLabour("")
       }else{
        setLabourList([...labourList,{name:labour}])
        setLabour("")
       }
    }

     
    



  return (
    <Grid2 container size={12} spacing={"1rem"}>
        <Grid2 container size={12} alignItems={"center"}>
         <Grid2 container size={4} >
        <Box
          sx={{
            backgroundColor: commonCss.shadeGreen,
            display:"flex",
            alignItems:"cente",
            p:1,
            cursor:"pointer"
          }}
          onClick={handleCloseClick}
        >
          <ArrowBack color={commonCss.green} />
        </Box>
        </Grid2>
        <Grid2 container size={4} justifyContent={"center"}>
          <Typography variant="h5" fontWeight="bold" color="white">
          Risk Assessment
          </Typography>
        </Grid2>
        <Grid2 container size={4} justifyContent={"flex-end"}>
        <Box
          sx={{
            backgroundColor: commonCss.shadeGreen,
            p: 1,
          }}
        >
          <Typography variant="body1" fontWeight="bold">
            Permit To Work No. <span style={{ color: "#e53e3e" }}>2701</span>
          </Typography>
        </Box>
        </Grid2>
        <Divider sx={{ borderColor: commonCss.red, borderWidth: 1,width:'100%' }} />
        </Grid2>
       
        <Grid2 container size={12}>
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
         
        <Grid2 container size={12} spacing={"0.6rem"}>
        <Grid2 size={{xs:12,md:6}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Risk Assessment of
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          
          <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Date and Time
              </Typography>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem>
                <DateTimePicker
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: commonCss.inputBackgroundColor, 
                      borderRadius:commonCss.inputBorderRadius
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                     borderColor: commonCss.inputBorderColor, 
                    },
                  }}
                  fullWidth
                />
              </DemoItem>
            </LocalizationProvider>
          </Grid2> 
          <Grid2 container size={12} alignItems={"center"} spacing={1}>
          <Grid2  size={7}>
          <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Staff List
              </Typography>
              </InputLabel>
              <Box sx={{display:"flex",gap:"1rem",alignItems:'center'}}>
              <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={labour}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              onChange={(e)=>{setLabour(e.target.value)}}
            />
              <IconButton sx={{border:`3px solid ${commonCss.red}`,p:0}} onClick={handleAccessor} >
            <Add sx={{color:commonCss.red}}/>
         </IconButton>
              </Box>
          </Grid2>
         
          <Grid2 size={7} sx={{display:labourList.length!==0?"default":"none"}}>
            <Paper elevation={4} >
               {/* <Collapse in={labour} > */}
                <NormalTable data={labourList} columns={labourColumns} />
               {/* </Collapse > */}
               </Paper>
          </Grid2>
        </Grid2>
          <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Description of activity
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              multiline
              rows={2}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Description of equipment
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              multiline
              rows={2}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          
        </Grid2>
        
            </Paper>
        </Grid2>
        <Grid2 container size={12} >
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
         
        <Grid2 container size={12} spacing={"0.6rem"}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red} >
          
          HAZARD ANALYSIS WORKSHEET
          </Typography>
          </Grid2 >
        <Grid2 size={{xs:12,md:4}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Hazards Identification
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={getHazard.identification}
              onChange={(e)=>{
                setHazard({
                  ...getHazard,identification:e.target.value
                })
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Risk Assessment (Impact)
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={getHazard.impact}
              onChange={(e)=>{
                setHazard({
                  ...getHazard,impact:e.target.value
                })
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Risk Control (Existing Mitigation)
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={getHazard.control}
              onChange={(e)=>{
                setHazard({
                  ...getHazard,control:e.target.value
                })
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Additional Control (If any)
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={getHazard.additionalContorl}
              onChange={(e)=>{
                setHazard({
                  ...getHazard,additionalContorl:e.target.value
                })
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
           
           
           
          <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Those at Risk
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={getHazard.thoseAtRisk}
              onChange={(e)=>{
                setHazard({
                  ...getHazard,thoseAtRisk:e.target.value
                })
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2> 
          <Grid2 container size={{xs:12}} justifyContent={"center"}>
          
          <Button
            variant="contained"
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={handleHazard}
            
          >
           Add Worksheet
          </Button>
          </Grid2>
          <Grid2 size={{xs:12}} sx={{display:hazardData.length===0?"none":"default"}}>
            <Paper sx={{background:"transparent"}}>
            <NormalTableWithRadius data={hazardData} columns={hazardColumns}/>
            </Paper>
          </Grid2>
          <Grid2 size={{xs:12,md:6}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
              Overall Assessment of Risk (using Risk Assessment Matrix): Action needed: (inc responsible party and closeout target)
              </Typography>
            </InputLabel>
            <Select
                size="small"
                defaultValue=""
                displayEmpty
                inputProps={{ "aria-label": "Technician" }}
                sx={{
                  borderRadius: commonCss.inputBorderRadius,
                  backgroundColor: commonCss.inputBackgroundColor,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
                fullWidth
              >
                {/* <MenuItem value="tech1">Technician 1</MenuItem>
                <MenuItem value="tech2">Technician 2</MenuItem>
                <MenuItem value="tech3">Technician 3</MenuItem> */}
              </Select>
          </Grid2>
          <Grid2 container size={{xs:12,md:6}} alignItems={"flex-end"}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Comments (if any)
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          
        </Grid2>
        
            </Paper>
        </Grid2>
        <Grid2 container size={12}>
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:'100%',textTransform:"uppercase"}}
            >
         
        <Grid2 container size={12} spacing={"0.7rem"}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
         
          NON EXHAUSTIVE LIST OF HAZARD CATEGORIES
          </Typography>
          </Grid2 >
        <Grid2 container size={12}>
            {listOfInstruction.map((listOf,i)=>(
                <Grid2 size={4} key={i}>
                    <Typography  variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                        {listOf}
                    </Typography>
                </Grid2>
            ))}
        </Grid2>
        <Grid2 container size={12}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green} >
         
          WHEN CONDUCTING A RISK ASSESSMENT
          </Typography>
          </Grid2 >
          <Grid2 size={12} >
                    <Typography  variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                    The assessment should be carried out in good time prior to the relevant decision being made 
When a specific assessment is needed then such an assessment, rather than a generic assessment should be made 
The risk from each activity should be considered  
A team of people or employees with practical knowledge of the process/activity being assessed should be used as appropriate 
Consultants should be used where necessary 
All the hazards associated with a particular activity should be considered 
Hazards and risk controls should be linked 
The results of the assessment should be properly used.
                    </Typography>
                </Grid2>
        </Grid2>
        <Grid2 container size={12}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green} sx={{textTransform:"uppercase"}}>
          PS Pipeline to provide high resolution image
          </Typography>
          </Grid2 >
          <Grid2 size={12}>
            <img src={PSImageRiskAssessment} style={{width:"100%"}} alt="PS image"/>
          </Grid2>
          </Grid2>
          
        </Grid2>
        
            </Paper>
        </Grid2>
       
        <Grid2 container size={12} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            sx={{
              backgroundColor:commonCss.green,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"10vw"
            }}
            // onClick={()=>{setLabour(true)}}
          >
           Submit
          </Button>
          </Grid2>

          
    </Grid2>
  )
}

export default RiskAssessment