import { Form, Formik } from 'formik'
import moment from 'moment'
import React, { useState } from 'react'
import { commonCss } from '../../../StyleConfig/CommonCss'
import { Button, Grid2, InputLabel, TextField, Typography } from '@mui/material'
import { DateTimePicker, LocalizationProvider, renderTimeViewClock } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoItem } from '@mui/x-date-pickers/internals/demo'
import dayjs from 'dayjs'

const GasTestComponent = ({edit,onSubmitGas,editData,type,hotWorkInfo}) => {
    const [gasData,setGasData]=useState({
     o2:edit?editData.o2: "",
     cO2:edit?editData.cO2: "",
     h2S:edit?editData.h2S: "",
     lel:edit?editData.lel: "",
     isAGTApproved: true,
     agtApprovedDate:edit?editData.o2: moment().format("YYYY-MM-DD HH:MM"),
    })
    const handleGas=(name,value)=>{
      setGasData({...gasData,[name]:value})
    }
    const onSubmit=()=>{
     onSubmitGas(gasData)
     setGasData({o2:edit?editData.o2: "",
      cO2:edit?editData.cO2: "",
      h2S:edit?editData.h2S: "",
      lel:edit?editData.lel: "",
      isAGTApproved: true,
      agtApprovedDate:edit?editData.o2: moment().format("YYYY-MM-DD HH:MM"),})
    }
  return (
  
        <Grid2 container size={12} spacing={"0.6rem"}>
            
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  O2
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={gasData.o2}
                name='o2'
                onChange={(e)=>{handleGas(e.target.name,e.target.value)}}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  CO2
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name='cO2'
                value={gasData.cO2}
                onChange={(e)=>{handleGas(e.target.name,e.target.value)}}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  H2S
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={gasData.h2S}
                name='h2S'
                onChange={(e)=>{handleGas(e.target.name,e.target.value)}}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  LEL
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name='lel'
                value={gasData.lel}
                onChange={(e)=>{handleGas(e.target.name,e.target.value)}}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>

            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    value={dayjs(gasData.agtApprovedDate)}
                    onChange={(newValue) => {
                      if (newValue !== null) {
                        if (`${newValue.$d}` !== "Invalid Date") {
                          const dateFrom = moment(newValue.$d).format(
                            "YYYY-MM-DD HH:MM "
                          );
                          handleGas("agtApprovedDate",dateFrom)
                      
                    }}
                  }}

                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: commonCss.inputBackgroundColor,
                        borderRadius: commonCss.inputBorderRadius,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            {/* <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  AGT Approval
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={getGas.appro}
                onChange={(e) => {
                  setGas({
                    ...getGas,
                    appro: e.target.value,
                  });
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2> */}
            <Grid2 container size={{ xs: 12 }} justifyContent={"center"}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: commonCss.red,
                  borderRadius: commonCss.buttonBorderRadius,
                  textTransform: "none",
                  padding: "0.6rem 1rem",
                  color: commonCss.buttonTextColor,
                  width: "15vw",
                }}
                onClick={onSubmit}
              >
                Add
              </Button>
            </Grid2>
            
          </Grid2>
       
  )
}

export default GasTestComponent