import { Box, Divider, Grid2, IconButton, MenuItem, Select, Typography,InputLabel,TextField, Paper, Checkbox, FormControlLabel, Button } from '@mui/material'
import React, { useState } from 'react'
import { commonCss } from '../../StyleConfig/CommonCss'
import { Add, EditOutlined, Stop } from '@mui/icons-material'
import NormalTableWithRadius from '../../Component/NormalTableWithRadius'
import { DatePicker, DateTimePicker, LocalizationProvider, renderTimeViewClock, TimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoItem } from '@mui/x-date-pickers/internals/demo'
import moment from 'moment'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'

const PTWForm2 = ({data}) => {
  const [searchParams,setSearchParams]=useSearchParams()
    const [precautionsData,setPrecautionsData]=useState([])
    const [getPrecaution,setGetPrecaution]=useState({
     precautions:"",
     lPI_PI_Engineer:""
    })
    const [engineer,setEngineer]=useState(false) 
    const [shiftData,setShiftData]=useState([])
    const [editShift,setEditShit]=useState({bol:false,i:null})
    const [editPrecaution,setEditPrecaution]=useState({bol:false,i:null})

    const precautionsColumn=[
      {
        Header:"Precautions",
        accessor:"precautions"
      },
      {
        Header:"LPI/PI/Engineer",
        accessor:"lPI_PI_Engineer"
      },
      {
        accessor:"id",
        Cell: ({ cell }) => {
          return (
            <Box sx={{ whiteSpace: "nowrap",display:"flex",justifyContent:"center",width:"100%",alignItems:'center' }}>
            
              <IconButton 
              onClick={()=>{
                setEditPrecaution({bol:true,i:cell.row.id})
                setGetPrecaution(cell.row.original)
              }}
              >
                <EditOutlined sx={{color:commonCss.red}}/>
                
              </IconButton>
             
            </Box>
          );
        },
      }
    ]
    const shiftColumns=[
        {
          Header:"Date",
          accessor:"date"
        },
        {
          Header:"Start Shift",
          accessor:"start",
          Cell:({cell})=>{
            return(
                <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"inherit"}}>
                    {cell.value!==null?moment(cell.value).format("hh:mm a"):null}
                </Typography>
            )
        }
        },
        {
            Header:"Permit Holder",
            accessor:"startPH"
          },
          {
            Header:"OE/Contorller",
            accessor:"startOE"
          },
          {
            Header:"End Shift",
            accessor:"end",
            Cell:({cell})=>{
                return(
                    <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"inherit"}}>
                        {cell.value!==null?moment(cell.value).format("hh:mm a"):null}
                    </Typography>
                )
            }
          },
          {
              Header:"Permit Holder",
              accessor:"endPH"
            },
            {
              Header:"OE/Contorller",
              accessor:"endOE"
            },
        {
          accessor:"id",
          Cell: ({ cell }) => {
            return (
              <Box sx={{ whiteSpace: "nowrap",display:"flex",justifyContent:"center",width:"100%",alignItems:'center' }}>
              
                
                  <Button 
                  disabled={cell.row.original.end!==null}
                  onClick={()=>{
                    handleShift("end",cell.row.id)
                    
                  }}
                  sx={{color:cell.row.original.end!==null?"default":commonCss.red}}>
                    Stop
                    </Button>
                  
              
               
              </Box>
            );
          },
        }
      ]

    const handlePrecaution=()=>{
        if(editPrecaution.bol){
         let updatePrecaution=[...precautionsData]
         updatePrecaution[editPrecaution.i]=getPrecaution
         setPrecautionsData(updatePrecaution)
         setEditPrecaution({bol:false,i:null})
         setGetPrecaution({
          precautions:"",
          lPI_PI_Engineer:""
      })
        }else{
            
            setPrecautionsData([...precautionsData,getPrecaution])
           setGetPrecaution({
            precautions:"",
            lPI_PI_Engineer:""
        })
        
        }
        
    }
    const handleShift=(type,i)=>{
        if(type==="end"){
            console.log("test1")
         let updateShift=[...shiftData]
         updateShift[i]={...updateShift[i],
          
          end:moment().format(""),
          endPH:"Permit Holder",
          endOE:"OE",
      }
         setShiftData(updateShift)
        
        }else{
            setShiftData([...shiftData,{
              date:moment().format("YYYY-MM-DD"),
              start:moment().format(""),
              startPH:"Permit Holder",
              startOE:"OE",
              end:null,
              endPH:"",
              endOE:"",
          }])
        
        }
        
    }
  return (
    <Box
    sx={{
      
      display:"flex",
      flexDirection:"column",
      gap:"0.6rem"
    }}
  >
    
      <Grid2 container size={12} justifyContent={"center"}>
        <Typography variant="h5" fontWeight="bold" color={commonCss.green}>
          PSP PERMIT TO WORK FORM
        </Typography>
      </Grid2>
      <Grid2 container size={12} >
        <Grid2 size={{xs:6}}>
          <Typography variant="body1" fontWeight="bold">
          Date of issue : 6/8/2024
          </Typography>
        </Grid2>
        
        <Grid2 container size={{xs:6}} justifyContent={"flex-end"}>
          <Typography variant="body1" fontWeight="bold">
          No. <span style={{ color: commonCss.red,fontSize:"1.2rem" }}>{searchParams.get("no")}</span>
          </Typography>
        </Grid2>
        <Divider sx={{ borderColor: commonCss.red, borderWidth: 1,width:"100%" }} />
      </Grid2>
      <Grid2 container size={12} spacing={"1rem"}>
         <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          Precautions for the work (in addition to those overleaf, and stated in associated certificates)
          </Typography>
          </Grid2>
          <Grid2 container size={12} alignItems={"center"} spacing={"1rem"}>
          <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
              Specify additional precautions
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={getPrecaution.precautions}
              onChange={(e)=>{
                setGetPrecaution({...getPrecaution,precautions:e.target.value})
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:5}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
              LPI/PI/Engineer
              </Typography>
            </InputLabel>
            <Select
                size="small"
                defaultValue=""
                displayEmpty
                inputProps={{ "aria-label": "Technician" }}
                value={getPrecaution.lPI_PI_Engineer}
              onChange={(e)=>{
                setGetPrecaution({...getPrecaution,lPI_PI_Engineer:e.target.value})
              }}
                sx={{
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius,
                
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                }}
                fullWidth
              >
                <MenuItem value="eng1">Engineer</MenuItem>
                <MenuItem value="eng2">LPI</MenuItem>
                <MenuItem value="eng3"> PI</MenuItem>
              </Select>
          </Grid2>
          <Grid2 size={{xs:12,md:1}}>
         <IconButton sx={{border:`3px solid ${commonCss.red}`,p:0,mt:1.5}} onClick={handlePrecaution} >
            <Add sx={{color:commonCss.red}}/>
         </IconButton>
          </Grid2>
          <Grid2 size={{xs:8}} sx={{display:precautionsData.length===0?"none":"default"}}>
            <Paper sx={{background:"transparent"}}>
            <NormalTableWithRadius data={precautionsData} columns={precautionsColumn}/>
            </Paper>
          </Grid2>
          <Grid2 size={12}>
          <Typography  variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none",lineHeight:1.5}}>
          I certify that I have fully considered the implications of the work in line with the responsibilities detailed in the permit to work procedure and that the above precautions are adequate for the work to be carried out provided that the precautions are established and maintained.
            </Typography>
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
          <FormControlLabel
               
               control={
               <Checkbox 
               name={"engineer"} 
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               onChange={(e)=>{
                
                if(e.target.checked){
                  setEngineer(true)
                }else{
                  setEngineer(false)
                }
               }}
             />
               
               } label={"Engineering Advice Required"} />
          </Grid2>
          <Grid2 container size={{xs:12,md:8}} sx={{display:engineer?"default":"none"}} alignItems={"center"} spacing={"1rem"}>
           <Grid2 size={{xs:12,md:6}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Engineer
              </Typography>
            </InputLabel>
            <Select
                size="small"
                defaultValue=""
                displayEmpty
                inputProps={{ "aria-label": "Technician" }}
                sx={{
                  
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius,
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                }}
                fullWidth
              >
                <MenuItem value="eng1">Engineer 1</MenuItem>
                <MenuItem value="eng2">Engineer 2</MenuItem>
                <MenuItem value="eng3">Engineer 3</MenuItem>
              </Select>
          </Grid2>
          <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Date and Time
              </Typography>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem>
                <DateTimePicker
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: commonCss.inputBackgroundColor, 
                      borderRadius:commonCss.inputBorderRadius
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                     borderColor: commonCss.inputBorderColor, 
                    },
                  }}
                  fullWidth
                />
              </DemoItem>
            </LocalizationProvider>
          </Grid2> 
          </Grid2>
          <Grid2 container size={12}>
          <Grid2 size={{xs:12,md:4}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Operation Manager
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'white', 
                  borderRadius:"13px"
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
                },
              }}
            />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Date and Time
              </Typography>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem>
                <DateTimePicker
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: commonCss.inputBackgroundColor, 
                      borderRadius:commonCss.inputBorderRadius
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                     borderColor: commonCss.inputBorderColor, 
                    },
                  }}
                  fullWidth
                />
              </DemoItem>
            </LocalizationProvider>
          </Grid2> 
          </Grid2>
        </Grid2>
         
          </Grid2>
      <Grid2 container size={12} spacing={"1rem"}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          Permit Authorized By
          </Typography>
          </Grid2>
         
          <Grid2  size={4}>
            <Box sx={{width:"100%"}}>
              <Grid2 container size={12} spacing={"0.5rem"}>
              <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green}>
          HSSE VERIFIER
          </Typography>
              </Grid2> 
              <Grid2 size={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                Verified all the HSSE requirement in place including JMS and JSA
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Name
                  </Typography>
                </InputLabel>
                <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Date/Time
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DateTimePicker
                      ampm={false}
                      format="DD/MM/YYYY HH:mm"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor: commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      fullWidth
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
              </Grid2>
            </Box>
          </Grid2>
          <Grid2  size={4}>
            <Box sx={{width:"100%"}}>
              <Grid2 container size={12} spacing={"0.5rem"}>
              <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green}>
          PERMIT ISSUER (PI)
          </Typography>
              </Grid2> 
              <Grid2 size={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                Authorised for execution without additional certificates
                </Typography>
              </Grid2>
              <Grid2 container size={12} mt={"1.25rem"} >
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Name
                  </Typography>
                </InputLabel>
                <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Date/Time
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DateTimePicker
                      ampm={false}
                      format="DD/MM/YYYY HH:mm"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor: commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      fullWidth
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    From
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DateTimePicker
                      ampm={false}
                      format="DD/MM/YYYY HH:mm"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor: commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      fullWidth
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                   Until
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DateTimePicker
                      ampm={false}
                      format="DD/MM/YYYY HH:mm"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor: commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      fullWidth
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
              </Grid2>
              </Grid2>
            </Box>
          </Grid2>
          <Grid2  size={4}>
            <Box sx={{width:"100%"}}>
              <Grid2 container size={12} spacing={"0.5rem"}>
              <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green}>
          LEAD PERMIT ISSUER (LPI)
          </Typography>
              </Grid2> 
              <Grid2 size={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                Authorised for execution only in accordance with attached certificate
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Name
                  </Typography>
                </InputLabel>
                <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Date/Time
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DateTimePicker
                      ampm={false}
                      format="DD/MM/YYYY HH:mm"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor: commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      fullWidth
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    From
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DateTimePicker
                      ampm={false}
                      format="DD/MM/YYYY HH:mm"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor: commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      fullWidth
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                   Until
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DateTimePicker
                      ampm={false}
                      format="DD/MM/YYYY HH:mm"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor: commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      fullWidth
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
              </Grid2>
            </Box>
          </Grid2>
        </Grid2>
        <Grid2 container size={12} spacing={"1rem"}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          Permit validity (where work is going to last more than the duration of a day)
          </Typography>
          </Grid2>
          <Grid2 size={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                Permit issuer & Permit Holder confirm by signing that the precautions required to work safely have been complied with prior to starting work each day or work period. At the close of each day, the Permit Issuer & Permit Holder sign to confirm that the area has been left in a safe condition (although it is still not an operational area, i.e. it has been handed back) and that all staff have left the site.
                </Typography>
        </Grid2>
        <Grid2 container size={12} alignItems={"center"} spacing={"1rem"}>
            
              {/* <Grid2 size={{ xs: 12,md:4 }} sx={{display:editShift.bol?"none":"default"}}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Date
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DatePicker
                    //   ampm={false}
                    value={dayjs(getShift.date)}
                      format="DD/MM/YYYY"
                    //   viewRenderers={{
                    //     hours: renderTimeViewClock,
                    //     minutes: renderTimeViewClock,
                    //     seconds: renderTimeViewClock,
                    //   }}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor: commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      fullWidth
                      onChange={(newValue) => {
                        console.log(newValue);
                        if (newValue !== null) {
                          if (`${newValue.$d}` !== "Invalid Date") {
                            const dateFrom = moment(newValue.$d).format(
                              "YYYY-MM-DD"
                            );
                            setGetShift({
                                ...getShift,
                                date:dateFrom
                            })
                            // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                            // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                            // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                            // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                            // setFieldValue("installationDate", dateFormat);
                          }
                        }
                      }}
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
              <Grid2 size={{ xs: 12,md:4 }}sx={{display:editShift.bol?"none":"default"}}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Start Shift
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <TimePicker
                    //   ampm={false}
                    value={getShift.start!==null?dayjs(getShift.start):null}
                      format="hh:mm a"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor: commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      fullWidth
                      onChange={(newValue) => {
                        console.log(newValue)
                        if (newValue !== null) {
                            if (`${newValue.$d}` !== "Invalid Date") {
                              const dateFrom = moment(newValue.$d).format(
                                "YYYY-MM-DD hh:mm:ss a"
                              );
                              console.log(dateFrom)
                              setGetShift({
                                  ...getShift,
                                  start:dateFrom
                              })
                            }}}
                        }
                        
                      
                      
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
          <Grid2 size={{xs:12,md:4}}sx={{display:editShift.bol?"none":"default"}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
              Permit Holder
              </Typography>
            </InputLabel>
            <Select
                size="small"
                defaultValue=""
                displayEmpty
                inputProps={{ "aria-label": "Technician" }}
                value={getShift.startPH}
              
                sx={{
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius,
                
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                }}
                fullWidth
                onChange={(e)=>{
                    setGetShift({
                        ...getShift,
                        startPH:e.target.value
                    })
                }}
              >
                <MenuItem value="eng1">Engineer</MenuItem>
                <MenuItem value="eng2">LPI</MenuItem>
                <MenuItem value="eng3"> PI</MenuItem>
              </Select>
          </Grid2>
          <Grid2 size={{xs:12,md:4}}sx={{display:editShift.bol?"none":"default"}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
              OE/Controller
              </Typography>
            </InputLabel>
            <Select
                size="small"
                defaultValue=""
                displayEmpty
                inputProps={{ "aria-label": "Technician" }}
                value={getShift.startOE}
                onChange={(e)=>{
                    setGetShift({
                        ...getShift,
                        startOE:e.target.value
                    })
                }}
                sx={{
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius,
                
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                }}
                fullWidth
              >
                <MenuItem value="eng1">Engineer</MenuItem>
                <MenuItem value="eng2">LPI</MenuItem>
                <MenuItem value="eng3"> PI</MenuItem>
              </Select>
          </Grid2>
          <Grid2 size={{ xs: 12,md:3 }}sx={{display:!editShift.bol?"none":"default"}}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    End Shift
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <TimePicker
                    //   ampm={false}
                    value={getShift.end!==null?dayjs(getShift.end):null}
                      format="hh:mm a"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor: commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      fullWidth
                      onChange={(newValue) => {
                        console.log(newValue)
                        if (newValue !== null) {
                            if (`${newValue.$d}` !== "Invalid Date") {
                              const dateFrom = moment(newValue.$d).format(
                                "YYYY-MM-DD hh:mm:ss a"
                              );
                              console.log(dateFrom)
                              setGetShift({
                                  ...getShift,
                                  end:dateFrom
                              })
                            }}}
                        }
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
          <Grid2 size={{xs:12,md:4}}sx={{display:!editShift.bol?"none":"default"}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
              Permit Holder
              </Typography>
            </InputLabel>
            <Select
                size="small"
                defaultValue=""
                displayEmpty
                inputProps={{ "aria-label": "Technician" }}
                value={getShift.endPH}
                onChange={(e)=>{
                    setGetShift({
                        ...getShift,
                        endPH:e.target.value
                    })
                }}
                sx={{
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius,
                
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                }}
                fullWidth
              >
                <MenuItem value="eng1">Engineer</MenuItem>
                <MenuItem value="eng2">LPI</MenuItem>
                <MenuItem value="eng3"> PI</MenuItem>
              </Select>
          </Grid2>
          <Grid2 size={{xs:12,md:4}}sx={{display:!editShift.bol?"none":"default"}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
              OE/Controller
              </Typography>
            </InputLabel>
            <Select
                size="small"
                defaultValue=""
                displayEmpty
                inputProps={{ "aria-label": "Technician" }}
                value={getShift.endOE}
                onChange={(e)=>{
                    setGetShift({
                        ...getShift,
                        endOE:e.target.value
                    })
                }}
                sx={{
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius,
                
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                }}
                fullWidth
              >
                <MenuItem value="eng1">Engineer</MenuItem>
                <MenuItem value="eng2">LPI</MenuItem>
                <MenuItem value="eng3"> PI</MenuItem>
              </Select>
          </Grid2> */}
          <Grid2 size={{xs:12,md:3}}>
         {/* <IconButton sx={{border:`3px solid ${commonCss.red}`,p:0,mt:1.5}} onClick={handleShift} >
            <Add sx={{color:commonCss.red}}/>
         </IconButton> */}
          <Button
            variant="contained"
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={()=>{handleShift("start")}}
          >
           Start Shift
          </Button>
          </Grid2>
          <Grid2 size={{xs:12}} sx={{display:shiftData.length===0?"none":"default"}}>
            <Paper sx={{background:"transparent"}}>
            <NormalTableWithRadius data={shiftData} columns={shiftColumns}/>
            </Paper>
          </Grid2>
        
        </Grid2>
          </Grid2>
          <Grid2 container size={12} spacing={"1rem"}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          PERMIT CLOSE OUT
          </Typography>
          </Grid2>
         
          <Grid2  size={4}>
            <Box sx={{width:"100%"}}>
              <Grid2 container size={12} spacing={"0.5rem"}>
              <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green}>
          PERMIT HOLDER           
          </Typography>
              </Grid2> 
              <Grid2 size={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                Close Out – Work Completed
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Name
                  </Typography>
                </InputLabel>
                <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Date/Time
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DateTimePicker
                      ampm={false}
                      format="DD/MM/YYYY HH:mm"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor: commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      fullWidth
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
              <Grid2 container size={{xs:12}} justifyContent={"flex-start"}>
              <Button
            variant="contained"
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
          >
           Close
          </Button>
              </Grid2>
              </Grid2>
            </Box>
          </Grid2>
          <Grid2  size={4}>
            <Box sx={{width:"100%"}}>
              <Grid2 container size={12} spacing={"0.5rem"}>
              <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green}>
          PERMIT ISSUER / LEAD PERMIT ISSUER        
          </Typography>
              </Grid2> 
              <Grid2 size={12}>
                <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor} sx={{textTransform:"none"}}>
                Signing Off – Accepting completion of the work
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Name
                  </Typography>
                </InputLabel>
                <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
              </Grid2>
              <Grid2 size={{ xs: 12 }}>
                <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Date/Time
                  </Typography>
                </InputLabel>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoItem>
                    <DateTimePicker
                      ampm={false}
                      format="DD/MM/YYYY HH:mm"
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock,
                      }}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "outlined",
                        },
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: commonCss.inputBorderRadius,
                          backgroundColor: commonCss.inputBackgroundColor,
                        },
                        '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
                      }}
                      fullWidth
                    />
                  </DemoItem>
                </LocalizationProvider>
              </Grid2>
              <Grid2 container size={{xs:12}} justifyContent={"flex-start"}>
              <Button
            variant="contained"
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
          >
           Close
          </Button>
              </Grid2>
              </Grid2>
            </Box>
          </Grid2>
        
        </Grid2>
      </Box>
  )
}

export default PTWForm2