export const roleInfo={
  agt:"605ba1bc-fffa-4032-e1c6-08dcde22af85"
}

export const api = {
  account: {
    login: "/Account/authenticate",
  },
  PTWForm: {
    getAllForm: "/PTWForm/GetFormList",
    formInitiate:"/PTWForm/InitiatePTWForm",
    saveForm:"/PTWForm/SavePTWForm",
    getDetailById:"/PTWForm/GetPTWFormDetail"
  },
  terminal:{
    title:"Terminal",
    getAll:"/TerminalMaster/GetAll",
    add:"/TerminalMaster/Add",
    edit:"/TerminalMaster/Edit",
  },
  site:{
    title:"Site",
    getAll:"/SiteMaster/GetAll",
    add:"/SiteMaster/Add",
    edit:"/SiteMaster/Edit",
  },
  role:{
    title:"Role",
    getAll:"/RoleMaster/GetAll",
    add:"/RoleMaster/Add",
    edit:"/RoleMaster/Edit",
    getByUserType:"/RoleMaster/GetAllRolesByUserType"
  },
  company:{
    title:"Company",
    getAll:"/CompanyMaster/GetAll",
    add:"/CompanyMaster/Add",
    edit:"/CompanyMaster/Edit",
  },
  user:{
    title:"User",
    getAll:"/UserMaster/GetAll",
    add:"/UserMaster/Add",
    edit:"/UserMaster/Edit",
    getUserByCompanyId:"/UserMaster/GetUserByCompanyId",
    getUserByCompanyIdAndRoleId:"/UserMaster/GetCompanyUserByRoleId"
  },
  userType:{
    getAll:"/UserTypeMaster/GetAll",
  },
  hotWork:{
    getByHotWorkId:"/PTWHotWorkGasTest/GetAllByHotWorkId",
    addGasTest:"/PTWHotWorkGasTest/Add",
    editGasTest:"/PTWHotWorkGasTest/Edit",
    getDataByFormId:"/PTWHotWorkCertificate/GetAllByFormId",
    editHotWork:"/PTWHotWorkCertificate/Edit",
    getById:'/PTWHotWorkCertificate/Get'
  },
  confinedSpace:{
    getByCSId:"/PTWConfinedSpaceGasTest/GetAllByConfinedSpaceId",
    getDataByFormId:"/PTWConfinedSpaceCertificate/GetAllByFormId"
  },
  excavation:{
    getDataByFormId:"/PTWExcavationCertificate/GetAllByFormId"
  },
  systemOverriding:{
    getDataByFormId:"/PTWSystemOverridingCertificate/GetAllByFormId",
    getById:"/PTWSystemOverridingCertificate/Get",
    edit:"/PTWSystemOverridingCertificate/Edit"
  },
  isolation:{
    getDataByFormId:"/PTWIsolationCertificate/GetAllByFormId",
    getById:"/PTWIsolationCertificate/Get",
    edit:"/PTWIsolationCertificate/Edit"
  },
  liftingAndHoisting:{
    getDataByFormId:"/PTWLiftingAndHoistingCertificate/GetAllByFormId"
  },
  radiation:{
    getDataByFormId:"/PTWRadiationgCertification/GetAllByFormId"
  },
  attributeValue:{
    getByKeyName:"/AttributeValue/GetAllByKeyName"
  }

 };