import { Button, Divider, Grid2, Paper, Typography,TextField,Select,InputLabel,Box } from '@mui/material'
import React from 'react'
import { commonCss } from '../../../StyleConfig/CommonCss'
import { ArrowBack, FileUploadOutlined } from '@mui/icons-material'
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";

import LAHimage from "../../../Images/LAHimage.png"

const LiftingAndHoisting = ({handleCloseClick}) => {
  return (
    <Grid2 container size={12} spacing={"1rem"}>
        <Grid2 container size={12} alignItems={"center"}>
         <Grid2 container size={4} >
        <Box
          sx={{
            backgroundColor: commonCss.shadeGreen,
            display:"flex",
            alignItems:"cente",
            p:1,
            cursor:"pointer"
          }}
          onClick={handleCloseClick}
        >
          <ArrowBack color={commonCss.green} />
        </Box>
        </Grid2>
        <Grid2 container size={4} justifyContent={"center"}>
          <Typography variant="h5" fontWeight="bold" color="white">
          Lifting And Hoisting 
          </Typography>
        </Grid2>
        <Grid2 container size={4} justifyContent={"flex-end"}>
        <Box
          sx={{
            backgroundColor: commonCss.shadeGreen,
            p: 1,
          }}
        >
          <Typography variant="body1" fontWeight="bold">
            Permit To Work No. <span style={{ color: "#e53e3e" }}>2701</span>
          </Typography>
        </Box>
        </Grid2>
        <Divider sx={{ borderColor: commonCss.red, borderWidth: 1,width:'100%' }} />
        </Grid2>
        <Grid2 container size={12}>
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
         
        <Grid2 container size={12} spacing={"0.6rem"}>
        <Grid2 size={{xs:12,md:4}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Location
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Date and Time
              </Typography>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem>
                <DateTimePicker
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: commonCss.inputBackgroundColor, 
                      borderRadius:commonCss.inputBorderRadius
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                     borderColor: commonCss.inputBorderColor, 
                    },
                  }}
                  fullWidth
                />
              </DemoItem>
            </LocalizationProvider>
          </Grid2> 
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Zone
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Operator
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Lifting Supervisor
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
        </Grid2>
        
            </Paper>
        </Grid2>
        <Grid2 container size={12} >
            <Paper elevation={3}
             sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
         
        <Grid2 container size={12} spacing={"0.6rem"}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          HAZARDS IDENTIFIED
          </Typography>
          </Grid2 >
        <Grid2 size={{xs:12,md:4}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Overhead Utilities / Obstruction
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Ground Conditions
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Underground Utilities
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2> 
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Vehicle & Pedestrian Traffic
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2> 
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Pinch Point
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2> 
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Fall Hazards
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2> 
          <Grid2 size={{xs:12,md:8}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Other Equipment
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          
          
        </Grid2>
        
            </Paper>
        </Grid2>
        <Grid2 container size={12}>
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:'100%'}}
            >
         
        <Grid2 container size={12} spacing={"0.6rem"}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          PRECAUTIONS
          </Typography>
          </Grid2 >
        <Grid2 size={{xs:12,md:4}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Spotter / Signal Person
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Elevated Warning Signs
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Cribbing / Shoring / Trench Plate
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2> 
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              K-Rails / Jersey Barrier
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2> 
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Caution Tape / Barricade
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2> 
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Fall Protection
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:8}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Other
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          
        </Grid2>
        
            </Paper>
        </Grid2>
        <Grid2 container size={12}>
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
         
        <Grid2 container size={12} spacing={"0.6rem"}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          LOAD CALCULATIONS
          </Typography>
          </Grid2 >
          <Grid2 container size={8}>
          <Grid2 size={{xs:12,md:6}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Width / Length / Height / Weight
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:6}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
              Number of Lift Points 
              </Typography>
            </InputLabel>
            <Select
                size="small"
                defaultValue=""
                displayEmpty
                inputProps={{ "aria-label": "Technician" }}
                sx={{
                  borderRadius: commonCss.inputBorderRadius,
                  backgroundColor: commonCss.inputBackgroundColor,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
                fullWidth
              >
                {/* <MenuItem value="tech1">Technician 1</MenuItem>
                <MenuItem value="tech2">Technician 2</MenuItem>
                <MenuItem value="tech3">Technician 3</MenuItem> */}
              </Select>
          </Grid2>
          <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Number of Slings 
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Length of Slings 
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2> 
          <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Distance Between Hook & Load 
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2> 
          <Grid2 size={{xs:12,md:6}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Sling Tension 
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2> 
          </Grid2>
          <Grid2 container size={4}>
            <img src={LAHimage} alt="lifitingHostingImage" style={{width:"100%",height:"100%"}}/>
          </Grid2>
          
        </Grid2>
        
            </Paper>
        </Grid2>
        <Grid2 container size={12}>
            <Paper elevation={3}
            sx={{borderRadius:commonCss.paperBorderRadius,p:1.5,background:commonCss.shadeLinearGradient,width:"100%"}}
            >
         
        <Grid2 container size={12} spacing={"0.6rem"}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          LOAD INFORMATION
          </Typography>
          </Grid2 >
          <Grid2 size={{xs:12,md:6}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
              Diagram of Load (Include Measurements) 
              </Typography>
            </InputLabel>
            <Box component={"label"} 
            sx={{background:commonCss.inputBackgroundColor,display:"flex",gap:"0.3rem",justifyContent:"center",alignItems:"center",
            width:"100%",height:"10vh",cursor:"pointer",border:`1px solid ${commonCss.inputBorderColor}`,borderRadius:commonCss.inputBorderRadius}}>
            <FileUploadOutlined />{" "}
                  <Typography sx={{ fontSize: "0.9rem" }}>Upload</Typography>
                  <input
                    type="file"
                    accept={"image/*"}
                    hidden
                    onChange={(e) => {
                      console.log(e.target.files[0]);
                      if (e.target.files[0] !== undefined) {
                        const file = e.target.files[0];
                        console.log(file.name);
                        const fileType = file.type.split("/")[1];
                        console.log(fileType);
                        // if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {

                        //   toast.error("Invalid format");
                        // } else {
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function () {
                          // console.log(reader.result);
                          const dataSplit = reader.result.split("base64,")[1];
                          // console.log(dataSplit)
                        
                         
                        };
                        reader.onerror = function (error) {};
                        // }
                      } else {

                      }
                    }}
                  />
            </Box>
          </Grid2>
          <Grid2 size={{xs:12,md:6}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
              Diagram of Proposed Rigging & Slings (Include Measurements) 
              </Typography>
            </InputLabel>
            <Box component={"label"}
             sx={{background:commonCss.inputBackgroundColor,display:"flex",gap:"0.3rem",justifyContent:"center",alignItems:"center",
              width:"100%",height:"10vh",cursor:"pointer",border:`1px solid ${commonCss.inputBorderColor}`,borderRadius:commonCss.inputBorderRadius}}>
            <FileUploadOutlined />{" "}
                  <Typography sx={{ fontSize: "0.9rem" }}>Upload</Typography>
                  <input
                    type="file"
                    accept={"image/*"}
                    hidden
                    onChange={(e) => {
                      console.log(e.target.files[0]);
                      if (e.target.files[0] !== undefined) {
                        const file = e.target.files[0];
                        console.log(file.name);
                        const fileType = file.type.split("/")[1];
                        console.log(fileType);
                        // if (!file.name.toLowerCase().match(/\.(jpg|jpeg|png|gif)$/)) {

                        //   toast.error("Invalid format");
                        // } else {
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = function () {
                          // console.log(reader.result);
                          const dataSplit = reader.result.split("base64,")[1];
                          // console.log(dataSplit)
                        
                         
                        };
                        reader.onerror = function (error) {};
                        // }
                      } else {

                      }
                    }}
                  />
            </Box>
          </Grid2>
          
        </Grid2>
        
            </Paper>
        </Grid2>
        <Grid2 container size={4}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"1rem"}>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.mainLabelHeading}
                color={commonCss.red}
                textAlign={"center"}
              >
                Lifting Inspectator
              </Typography>
              <Divider
                sx={{
                  borderColor: commonCss.red,
                  borderWidth: 1,
                  width: "100%",
                  mt: 1,
                }}
              />
            </Grid2>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.normalSentenceWeight}
                color={commonCss.normalSenternceColor}
                sx={{ textTransform: "none" }}
              >
                I certify that I have reviewed the proposed work and I am
                satisfied that it is adequately described on the permit to work
                and that the precautions detailed are adequate and clearly
                defined
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Name
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      
      <Grid2 container size={4}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"1rem"}>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.mainLabelHeading}
                color={commonCss.red}
                textAlign={"center"}
              >
                LPI
              </Typography>
              <Divider
                sx={{
                  borderColor: commonCss.red,
                  borderWidth: 1,
                  width: "100%",
                  mt: 1,
                }}
              />
            </Grid2>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.normalSentenceWeight}
                color={commonCss.normalSenternceColor}
                sx={{ textTransform: "none" }}
              >
                Lifting And Hoisting is described in the permit to work is allowed
                between:
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Name
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  From
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Until
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={4}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"1rem"}>
            <Grid2 item xs={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.normalSentenceWeight}
                color={commonCss.normalSenternceColor}
                sx={{ textTransform: "none" }}
              >
                Lifting And Hoisting is certificate and permit to work are
                withdrawn:
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Name
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Reason
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                multiline
                minRows={2}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
        <Grid2 container size={12} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            sx={{
              backgroundColor:commonCss.green,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"10vw"
            }}
            // onClick={()=>{setLabour(true)}}
          >
           Submit
          </Button>
          </Grid2>

        
    </Grid2>
  )
}

export default LiftingAndHoisting