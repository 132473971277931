import { Container } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'

const ViewImage = () => {
    const [searchParams,setSearchParams]=useSearchParams()

  
  return (
    <Container>
        <img src={searchParams.get("index")} style={{width:"100%",height:"100%"}}/>
    </Container>
  )
}

export default ViewImage