import React, { Fragment, useState } from "react";
import {
  Grid2,
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  FormControl,
  RadioGroup,
  Radio,
  List,
  ListItem,
  Paper,
  MenuItem,
  Button,
  Select,
  Container,
  IconButton,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Divider,
  InputLabel,
  FormGroup,
  Dialog,
  Collapse,
  DialogContent,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";

import NormalTable from "../../Component/NormalTable";
import { Add, AddCircleOutline, DeleteOutline, EditOutlined, KeyboardArrowDown, KeyboardArrowUp, Minimize, PlusOne, UploadFile } from "@mui/icons-material";
import { commonCss } from "../../StyleConfig/CommonCss";
import CertificateSelect from "./CertificateSelect";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import RadiationCertificate from "./Certificate/RadiationCertificate";
import LabourSelect from "./LabourSelect";
import MethodStatement from "./Forms/MethodStatement";
import RiskAssessment from "./Forms/RiskAssessment";
import IsolationCertificate from "./Certificate/IsolationCertificate";
import LiftingAndHoisting from "./Certificate/LiftingAndHoisting";
import SafeOverride from "./Certificate/SafeOverride";
import Excavation from "./Certificate/Excavation";
import ConfinedSpaceCertificate from "./Certificate/ConfinedSpaceCertificate";
import HotWork from "./Certificate/HotWork";
import { api } from "../API/config";
import { axiosPrivate } from "../../axios/axios";

const PTWForm1 = ({data,getData}) => {
  // const certificateName=useSelector(state=>state.data.certificateName)
  const certificateCount=useSelector(state=>state.data.certificateCount)

  const [searchParams,setSearchParams]=useSearchParams()
  
  const [technician,setTechnician]=useState("")
  const [certificateInfo,setCertificateInfo]=useState({})
  const [selectedOptions, setSelectedOptions] = useState({
    excavation: false,
    enteringConfinedSpace: false,
    assemble: false,
    disassemble: false,
    prefabrication: false,
    openingConfinedSpace: false,
    welding: false,
    workingAtHeight: false,
    cutting: false,
    drillingGrinding: false,
    highPressureJetting: false,
    gritBlasting: false,
    xrayRadiography: false,
    liftingCraneActivities: false,
    calibration: false,
    electricalActivities: false,
    nitrogenPressureTest: false,
    hydrostaticTest: false,
    other: true,
  });
  const [riskLevel,setRiskLevel]=useState("")
  const [riskLevelSelect,setRiskLevelSelect]=useState([])
  const [certificateOpen,setCertificateOpen]=useState(false)
  const [certificateAddList,setCertificateAddList]=useState({})
  const [certificateListOpen,setCertificateListOpen]=useState({
        hotWork:false,
        confinedSpace:false,
        radiation:false,
        excavation:false,
        isolation:false,
        safe:false,
        lifting:false
  })
  const [certificateDataList,setCertificateDataList]=useState({
    enableHotWorkCertificate:[],
    enableConfinedSpaceCertificate:[],
    enableSystemOverridingCertificate:[],
    enableExcavationCertificate:[],
    enableIsolationCertificate:[],
    enableLiftingAndHoistingCertificate:[],
    enableRadiationCertificate:[],

  })
  const [labourList,setLabourList]=useState([])
    
    const [labour,setLabour]=useState("")
    const [editLabour,setEditLabour]=useState({bol:false,i:null})
  const [classifiedWithWhereOther,setClassifiedOther]=useState(false)
  const [oiOthers,setOIOthers]=useState(false)
  const [workAreaOthers,setWorkAreaOthers]=useState(false)
  const [riskLevelBol,setRiskLevelBol]=useState({
    riskAssessment:false,
    method:false
  })
  const [locationBol,setLocationBol]=useState(false)
  const [terminal,setTerminal]=useState("")

  const [dataInfo,setDataInfo]=useState({
    description:"",
    location:""
  })

  const navigate=useNavigate()

  const handleChange = (event) => {
    setSelectedOptions({
      ...selectedOptions,
      [event.target.name]: event.target.checked,
    });
  };

  const certificateName=[
    {
        name:"enableHotWorkCertificate",
        label:"Hot Work",
        api:"hotWork"
    },
    {
        name:"enableConfinedSpaceCertificate",
        label:"Confined Space",
        api:"confinedSpace"
    },
    {
        name:"enableSystemOverridingCertificate",
        label:"System Overriding",
        api:"systemOverriding"
    },
    {
        name:"enableExcavationCertificate",
        label:"Excavation",
        api:"excavation"
    },
    {
        name:"enableIsolationCertificate",
        label:"Isolation",
        api:"isolation"
    },
    {
        name:"enableLiftingAndHoistingCertificate",
        label:"Lifting & Hoisting",
        api:"liftingAndHoisting"
    },
    {
        name:"enableRadiationCertificate",
        label:"Radiation",
        api:"radiation"
    },
]

 
  const [checked, setChecked] = useState({
    safetyGlasses: false,
    leatherGloves: false,
    earProtection: false,
    helmet: false,
    safetyHarness: false,
    breathingProtection: false,
    fullFaceMask: false,
    fallProtection: false,
    safetyJocket: false,
  });

  const certificateData={
    hotWork:[
      {
        id:1,
        no:1,
        validity:"1 Day",
        Status:"Progres",
        name:"hotWork"
      }
    ],
    confinedSpace:[
      {
        id:1,
        no:1,
        validity:"1 Day",
        Status:"Progres",
        name:"confinedSpace"
      }
    ],radiation:[
      {
        id:1,
        no:1,
        validity:"1 Day",
        Status:"Progres",
        name:"radiation"
      }
    ],excavation:[
      {
        id:1,
        no:1,
        validity:"1 Day",
        Status:"Progres",
        name:"excavation"
      }
    ],isolation:[
      {
        id:1,
        no:1,
        validity:"1 Day",
        Status:"Progres",
        name:"isolation"
      }
    ],safe:[
      {
        id:1,
        no:1,
        validity:"1 Day",
        Status:"Progres",
        name:"safe"
      }
    ],lifting:[
      {
        id:1,
        no:1,
        validity:"1 Day",
        Status:"Progres",
        name:"lifting"
      }
    ],

  }
  
  const certificateColumns=[
    {
      Header:"No",
      accessor: "no",
      Cell: ({ cell }) => {
        return (
          <Typography
            sx={{
              fontWeight: 500,
            }}
          >
            {Number(cell.row.id)+1}
          </Typography>
        );
      },
      // disableFilters: true,
    },
    {
      Header:"Status",
      accessor: "Status",
      // disableFilters: true,
    },
    {
      Header:"Validity",
      accessor: "validity",
      // disableFilters: true,
    },
    {
      accessor:"id",
      Cell: ({ cell }) => {
        return (
          <Box sx={{ whiteSpace: "nowrap",display:"flex",justifyContent:"flex-end",width:"100%",alignItems:"center" }}>
          
            <IconButton onClick={()=>{
              setCertificateInfo(cell.row.original)
              if(cell.row.original.type==="enableHotWorkCertificate"){
                setCertificateListOpen({...certificateListOpen,hotWork:true})
              }else if(cell.row.original.type==="enableConfinedSpaceCertificate"){
                setCertificateListOpen({...certificateListOpen,confinedSpace:true})
              }
              else if(cell.row.original.type==="enableRadiationCertificate"){
                setCertificateListOpen({...certificateListOpen,radiation:true})
              }
              else if(cell.row.original.type==="enableExcavationCertificate"){
                setCertificateListOpen({...certificateListOpen,excavation:true})
              }
              else if(cell.row.original.type==="enableIsolationCertificate"){
                setCertificateListOpen({...certificateListOpen,isolation:true})
              }
              else if(cell.row.original.type==="enableSystemOverridingCertificate"){
                setCertificateListOpen({...certificateListOpen,safe:true})
              }else{
                setCertificateListOpen({...certificateListOpen,lifting:true})
              }
            }}>
              <EditOutlined sx={{color:commonCss.red}}
              
              />
            </IconButton>
            <IconButton sx={{}}>
              <Minimize sx={{color:commonCss.red}}/>
            </IconButton>
           
          </Box>
        );
      },
    }
    
  ]


  const labourColumns= [
    // {
    //   Header:"Code",
    //   accessor: "code",
    //   // disableFilters: true,
    // },
    {
      Header:"Labour Name",
      accessor: "name",
      // disableFilters: true,
    },
    {
      accessor:"id",
      Cell: ({ cell }) => {
        return (
          <Box sx={{ whiteSpace: "nowrap",display:"flex",justifyContent:"flex-end",width:"100%",alignItems:'center' }}>
          
            <IconButton >
              <DeleteOutline sx={{color:commonCss.red}}/>
            </IconButton>
           
           
          </Box>
        );
      },
    }
    
  ]


  const riskLevelColumns=[
    {
      accessor: "name",
      // disableFilters: true,
    },
    {
      accessor:"id",
      Cell: ({ cell }) => {
        return (
          <Box sx={{ whiteSpace: "nowrap",display:"flex",justifyContent:"flex-end",width:"100%",alignItems:'center' }}>
          
            <IconButton sx={{display:cell.row.original.name!=="JMS"&&cell.row.original.name!=="JSA"?"default":"none"}}
            onClick={()=>{
              if(cell.row.original.name==="Risk Assesment"){
                setRiskLevelBol({...riskLevelBol,riskAssessment:true})
              }else{
                setRiskLevelBol({...riskLevelBol,method:true})
              }
            }}
            >
              <EditOutlined sx={{color:commonCss.red}}/>
              
            </IconButton>
            <IconButton component={"label"} sx={{display:cell.row.original.name==="JMS"||cell.row.original.name==="JSA"?"default":"none"}}>
              <UploadFile sx={{color:commonCss.red}}/>
              <input
                  type="file"
                  hidden
                  onChange={(e) => {
                    if (e.target.files[0] !== undefined) {
                      const file = e.target.files[0];
                      let reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = function () {
                        const dataSplit = reader.result.split("base64,")[1];
                        // Process the base64 data here
                      };
                      reader.onerror = function (error) {};
                    }
                  }}
                />
            </IconButton>
           
          </Box>
        );
      },
    }
    
  ]
  const riskLevelTypes=[
    {
      name:"riskAssesment",
      label:"Risk Assesment",
      flag:riskLevel==="low"
    },
    {
      name:"methodStatement",
      label:"Method Statement",
      flag:riskLevel==="low"
    },
    {
      name:"jms",
      label:"JMS",
      flag:true
    },
    {
      name:"jsa",
      label:"JSA",
      flag:true
    },
  ]

  const classifiedWhere=[
    {
      value:"",
      name:"1",
      label:"Zone 0 - Product Tank, Interceptor, Confine Space. "
    },
    {
      value:"",
      name:"2",
      label:"Zone 1 - Loading Gantry, MPP Receiving, Tank Farm, Additive Area, MPP Pump & Receiving Stations. "
    },{
      value:"",
      name:"3",
      label:" Zone 2 - The rest of KVDT Terminal's Area and MPP Block Valve Stations."
    },
    {
      value:"",
      name:"4",
      label:"UnClassified - Area Outside Terminal. "
    },
  ]
  const classifiedWithWhat=[
    {
      value:"",
      name:"1",
      label:"Explosion Proof/Intrinsically Safe"
    },
    {
      value:"",
      name:"2",
      label:"Spark Free Tools"
    },{
      value:"",
      name:"3",
      label:"Lifting Equipment/Crane"
    },
    {
      value:"",
      name:"4",
      label:"Scaffold"
    },
    {
      value:"",
      name:"5",
      label:"Other"
    },
  ]
  const oiWhere=[
    {
      value:"",
      name:"1",
      label:"Excavation"
    },
    {
      value:"",
      name:"2",
      label:"Opening Confined Space"
    },{
      value:"",
      name:"3",
      label:"High-pressure Jetting"
    },
    {
      value:"",
      name:"4",
      label:"Electrical Activities"
    },
    {
      value:"",
      name:"5",
      label:"Entering Confined Space"
    },
    {
      value:"",
      name:"6",
      label:"Welding"
    },
    {
      value:"",
      name:"7",
      label:"Grit Blasting"
    },
    {
      value:"",
      name:"8",
      label:"Nitrogen pressure test"
    },
    {
      value:"",
      name:"9",
      label:"Assemble"
    },
    {
      value:"",
      name:"6",
      label:"Working at Height"
    },
    {
      value:"",
      name:"7",
      label:"X-ray/Radiography"
    },
    {
      value:"",
      name:"8",
      label:"Hydrostatic test"
    },
    {
      value:"",
      name:"9",
      label:"Disassemble"
    },
    {
      value:"",
      name:"8",
      label:"Cutting"
    },
    {
      value:"",
      name:"8",
      label:"Prefabrication"
    },
    {
      value:"",
      name:"9",
      label:"Drilling/Grinding"
    },
    {
      value:"",
      name:"9",
      label:"Calibration"
    },
    {
      value:"",
      name:"9",
      label:"Other"
    },
  ]
  const apWhere=[
    {
      value:"",
      name:"1",
      label:"Safety Glasses"
    },
    {
      value:"",
      name:"2",
      label:"Helmet"
    },{
      value:"",
      name:"3",
      label:"Full Face Mask"
    },
    {
      value:"",
      name:"4",
      label:"Leather Gloves"
    },
    {
      value:"",
      name:"5",
      label:"Safety Harness"
    },
    {
      value:"",
      name:"6",
      label:"Fall Protection"
    },
    {
      value:"",
      name:"7",
      label:"Ear Protection"
    },
    {
      value:"",
      name:"8",
      label:"Breathing Protection"
    },
    {
      value:"",
      name:"9",
      label:"Safety Shoes"
    },
    {
      value:"",
      name:"10",
      label:"Neoprene Gloves"
    },
    
  ]

  const workingArea=[
    {
      value:"",
      name:"1",
      label:"Demarcation"
    },
    {
      value:"",
      name:"2",
      label:"Fire Extinguisher"
    },{
      value:"",
      name:"3",
      label:"Use of Warning Signs"
    },
    {
      value:"",
      name:"4",
      label:"Others"
    },
    
  ]
  const mechnicalOM=[
    {
      value:"",
      name:"1",
      label:"Pressure free"
    },
    {
      value:"",
      name:"2",
      label:"Empty"
    },
    {
      value:"",
      name:"3",
      label:"Disconnected"
    },
    {
      value:"",
      name:"4",
      label:"Blanked off"
    },
    {
      value:"",
      name:"3",
      label:"Locked off"
    },
    {
      value:"",
      name:"4",
      label:"Flushed"
    },
    
  ]
  const electricalOM=[
    {
      value:"",
      name:"1",
      label:"Locked off"
    },
    {
      value:"",
      name:"2",
      label:"Isolated"
    },
    
  ]
  const instrumentationOM=[
    {
      value:"",
      name:"1",
      label:"Locked off"
    },
    {
      value:"",
      name:"2",
      label:"Isolated"
    },
    {
      value:"",
      name:"3",
      label:"Disconnected"
    },
    {
      value:"",
      name:"4",
      label:"Bypass"
    },
    
  ]
  const measureList=[
    {
      value:"",
      name:"1",
      label:"Shut down system operational"
    },
    {
      value:"",
      name:"2",
      label:"Fire protection system operational"
    },
    {
      value:"",
      name:"3",
      label:"Automatic fire protection system operational"
    },
    
    
  ]



  const handleChangeForPPE = (event) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };

  const handleRiskLevel=(e)=>{
    setRiskLevel(e.target.value)
  }
  const handleCheckRiskLevel=(e,name,id)=>{
    if(e.target.checked){
      setRiskLevelSelect([...riskLevelSelect,{name:name,id:id}])
    }else{
      setRiskLevelSelect(riskLevelSelect.filter(fil=>fil.name!==name))
    }
  }

  const handleStaff=()=>{
    if(editLabour.bol){
     let updateLabour=[...labourList] 
     updateLabour[editLabour.i]={name:labour}
     setLabourList(updateLabour)
     setEditLabour({bol:false,i:null})
     setLabour("")
    }else{
     setLabourList([...labourList,{name:labour}])
     setLabour("")
    }
 }

 const handleCertificate=async(nameCert)=>{
  const id= searchParams.get("id")
  if(certificateAddList[nameCert.name]){
    setCertificateAddList({...certificateAddList,[nameCert.name]:false})
  }else{
  const getLink=api[nameCert.api].getDataByFormId
  await axiosPrivate.get(`${getLink}?formId=${id}`).then(res=>{
    setCertificateAddList({...certificateAddList,[nameCert.name]:certificateAddList[nameCert.name]?false:true})
    setCertificateDataList({...certificateDataList,[nameCert.name]:res.data.data})
    console.log(res)
  }).catch(e=>{
    console.log(e)
    setCertificateAddList({...certificateAddList,[nameCert.name]:false})
    setCertificateDataList({...certificateDataList,[nameCert.name]:[]})
  })
}


 }



  return (
    <Box
      sx={{
        
        display:"flex",
        flexDirection:"column",
        gap:"0.6rem"
      }}
    >
      
        <Grid2 container size={12} justifyContent={"center"}>
          <Typography variant="h5" fontWeight="bold" color="#00807F">
            PSP PERMIT TO WORK FORM
          </Typography>
        </Grid2>
        <Grid2 container size={12} >
          <Grid2 size={{xs:6}}>
            <Typography variant="body1" fontWeight="bold">
            Date of issue : 6/8/2024
            </Typography>
          </Grid2>
          
          <Grid2 container size={{xs:6}} justifyContent={"flex-end"}>
            <Typography variant="body1" fontWeight="bold">
            No. <span style={{ color: "#FD0000",fontSize:"1.2rem" }}>{searchParams.get("no")}</span>
            </Typography>
          </Grid2>
          <Divider sx={{ borderColor: "#FD0000", borderWidth: 1,width:"100%" }} />
        </Grid2>
        <Grid2 container size={12} alignItems={"center"} spacing={"1rem"}>
        <Grid2 size={{xs:12,md:4}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Terminal
              </Typography>
            </InputLabel>
            <Select
                size="small"
                defaultValue=""
                displayEmpty
                inputProps={{ "aria-label": "Technician" }}
                sx={{
                  borderRadius: "13px",
                  backgroundColor: "white",
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '747373',
                  },
                }}
                onChange={(e)=>{
                   if(e.target.value==="tem-1"){
                    setLocationBol(false)
                   
                   }else{
                    setLocationBol(true)
                   }
                   setTerminal(e.target.value)
                }}
                fullWidth
              >
                <MenuItem value="tem-1">PS Pipeline - Terminal 1</MenuItem>
                <MenuItem value="tem-2">PS Terminal Bintulu - Terminal 2</MenuItem>
                <MenuItem value="tem-3">PS Terminal Tawau - Terminal 3</MenuItem>
              </Select>
          </Grid2>
          <Grid2 size={{xs:12,md:4}} sx={{display:terminal==="tem-1"?"default":"none"}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Site
              </Typography>
            </InputLabel>
            <Select
                size="small"
                defaultValue=""
                displayEmpty
                inputProps={{ "aria-label": "Technician" }}
                sx={{
                  borderRadius: "13px",
                  backgroundColor: "white",
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '747373',
                  },
                }}
                onChange={(e)=>{
                  setLocationBol(true)
                }}
                fullWidth
              >
                <MenuItem value="site1">Melaka</MenuItem>
                <MenuItem value="site2">PD</MenuItem>
                <MenuItem value="site3">KLIA</MenuItem>
                <MenuItem value="site4">Sepang</MenuItem>
                <MenuItem value="site5">KVDT</MenuItem>
                <MenuItem value="site6">ROW</MenuItem>
              </Select>
          </Grid2>
          <Grid2 size={{xs:12,md:4}} sx={{display:locationBol?"default":"none"}}>
            <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Location
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={dataInfo.locaiton}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: 'white', 
                  borderRadius:"13px"
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
                },
              }}
              onChange={(e)=>{setDataInfo({...dataInfo,location:e.target.value})}}
            />
          </Grid2>
          <Grid2 container size={{xs:12,md:4}} justifyContent={"flex-start"} mt={1.8} sx={{display:terminal==="tem-1"?"default":"none"}}>
           <FormControl>
                <RadioGroup
                  name="formType"
                  row
                >
                  <FormControlLabel
                    value="KVDT"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fill: "#00807F",
                          },
                          "&:checked": {
                            color: "#00807F",
                          },
                          
                        }}
                      />
                    }
                    label="KVDT"
                  />
                  <FormControlLabel
                    value="MPP"
                    control={
                      <Radio
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fill: "#00807F",
                          },
                          "&:checked": {
                            color: "#00807F",
                          },
                         
                        }}
                      />
                    }
                    label="MPP"
                  />
                </RadioGroup>
              </FormControl>
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
          <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Technician
              </Typography>
            </InputLabel>
            <Select
                size="small"
                defaultValue=""
                displayEmpty
                value={technician}
                inputProps={{ "aria-label": "Technician" }}
                sx={{
                  borderRadius: "13px",
                  backgroundColor: "white",
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '747373',
                  },
                }}
                fullWidth
                onChange={(e)=>{setTechnician(e.target.value)}}
              >
                <MenuItem value="Technician-1">Technician 1</MenuItem>
                <MenuItem value="Technician-2">Technician 2</MenuItem>
                <MenuItem value="Technician-3">Technician 3</MenuItem>
              </Select>
          </Grid2>
          
        <Grid2 size={{xs:12,md:4}}>
        <InputLabel sx={{pl:0.5}}>
            <Typography variant="body2" fontWeight="500" color="black">
              Description of work
            </Typography>
          </InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            value={dataInfo.description}
            multiline
            maxRows={3}

            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white', 
                borderRadius:"13px"
              },
              '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
              },
            }}
            onChange={(e)=>{setDataInfo({...dataInfo,description:e.target.value})}}
          />
       
        </Grid2>
        </Grid2>
        <Grid2 container size={12} rowSpacing={"1rem"} columnSpacing={"2rem"} alignItems={"center"} >
          <Grid2  size={{xs:12,md:4}}>
            <InputLabel sx={{pl:0.5}}>
              <Typography variant="body2" fontWeight="500" color="black">
                Risk Level
              </Typography>
            </InputLabel>
            <Select
               fullWidth
                size="small"
                defaultValue=""
                displayEmpty
                inputProps={{ "aria-label": "Risk Level" }}
                sx={{
                  borderRadius: "13px",
                  backgroundColor: "white",
                  '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
                },
                  
                }}
                onChange={handleRiskLevel}
              >
                
                <MenuItem value="low">Low</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="high">High</MenuItem>
            </Select>

          </Grid2>
          <Grid2 container size={8} spacing={1} alignItems="center" sx={{display:riskLevel!==""?"default":"none",mt:2}}>
          <FormGroup row >
            {riskLevelTypes.map((typeLevel,i)=>(
               <FormControlLabel 
               key={i}
               sx={{display:typeLevel.flag?"default":"none", ml:1.5}}
               control={
               <Checkbox 
               name={typeLevel.name} 
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               onChange={(e)=>{handleCheckRiskLevel(e,typeLevel.label,i)}}
             />
               
               } label={typeLevel.label} />
            ))}
          
          </FormGroup>
           
          </Grid2>
          <Grid2 size={6} sx={{display:riskLevelSelect.length===0?"none":"default"}}>
            <Paper elevation={4}>
            <NormalTable data={riskLevelSelect} columns={riskLevelColumns} hideHeader={true}  />
            </Paper>
          </Grid2>
        </Grid2>
        <Grid2 container size={12} alignItems={"center"} spacing={1}>
          <Grid2 size={6}>
          <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Appendix – Associated Certificate
              </Typography>
              </InputLabel>
          </Grid2>
          <Grid2 size={6}>
            <Box sx={{display:"flex",justifyContent:"flex-end",width:"100%"}}>
            <Button
            variant="contained"
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={()=>{setCertificateOpen(true)}}
          >
            Add Certificate
          </Button>
            </Box>
          </Grid2>
          <Grid2 size={12}>
            
            {certificateName.map((nameCertificate,i)=>(
              <>
              <Box sx={{display:data[nameCertificate.name]?"default":"none"}}>
              <Box sx={{display:"flex",justifyContent:'space-between',alignItems:"center"}}>
                <Box sx={{cursor:"pointer",display:"flex",alignItems:"center"}}
                onClick={()=>{
                   handleCertificate(nameCertificate)
                }}
                >
                  <IconButton>
                    {certificateAddList[nameCertificate.name]?<KeyboardArrowUp sx={{color:commonCss.green}}/>:<KeyboardArrowDown sx={{color:commonCss.green}}/>}
                  </IconButton>
                <Typography sx={{fontSize:"0.9rem",
                  fontWeight:500,
                  color:commonCss.green,
                  cursor:'pointer'
                  }}

                  >
                  {nameCertificate.label}
                </Typography>
                </Box>
                <IconButton>
                  <AddCircleOutline sx={{color:commonCss.red}}/>
                </IconButton>
              </Box>
              <Divider sx={{width:"100%"}} />
              
              <Paper elevation={4} sx={{p:1,display:certificateAddList[nameCertificate.name]?"default":"none"}}>
               <Collapse in={certificateAddList[nameCertificate.name]} >
                <NormalTable 
                data={certificateDataList[nameCertificate.name].map(obj => {
                            return { ...obj, type: nameCertificate.name };
                             })} 
                columns={certificateColumns} />
               </Collapse >
               </Paper>
               </Box>
              </>
            ))}
         
          </Grid2>
        </Grid2>
        <Grid2 container size={12} spacing={"1rem"}>
          <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
            PERMIT HOLDER
          </Typography>
          </Grid2>
          <Grid2 size={{xs:12,md:4}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Name
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Company
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                No.of Staff Present
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={labourList.length}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          {/* <Grid2 container size={12} alignItems={"center"} spacing={1}>
          <Grid2 size={3}>
          <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Staff List
              </Typography>
              </InputLabel>
          </Grid2>
          <Grid2 size={4}>
            <Box sx={{display:"flex",justifyContent:"flex-end",width:"100%"}}>
            <Button
            variant="contained"
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={()=>{setLabour(true)}}
          >
            Add Staff
          </Button>
            </Box>
          </Grid2>
          <Grid2 size={7}>
            <Paper elevation={4} >
             
                <NormalTable data={labourList} columns={labourColumns} />
              
               </Paper>
          </Grid2>
        </Grid2> */}
        <Grid2 container size={12} alignItems={"center"} spacing={1}>
          <Grid2  size={6}>
          <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Staff List
              </Typography>
              </InputLabel>
              <Box sx={{display:"flex",gap:"1rem",alignItems:'center'}}>
              <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={labour}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              onChange={(e)=>{setLabour(e.target.value)}}
            />
              <IconButton sx={{border:`3px solid ${commonCss.red}`,p:0}} onClick={handleStaff} >
            <Add sx={{color:commonCss.red}}/>
         </IconButton>
              </Box>
          </Grid2>
         
          <Grid2 size={7} sx={{display:labourList.length!==0?"default":"none"}}>
            <Paper elevation={4} >
               {/* <Collapse in={labour} > */}
                <NormalTable data={labourList} columns={labourColumns} />
               {/* </Collapse > */}
               </Paper>
          </Grid2>
        </Grid2>
        </Grid2>
        <Grid2 container size={12} spacing={"1rem"}>
          <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          CLASSIFIED AREA
          </Typography>
          </Grid2>
          
          <Grid2 container size={8}  >
          
           <Grid2 size={12}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Where
              </Typography>
              </InputLabel>
           </Grid2>
          
          <Grid2 size={12} mt={classifiedWithWhereOther?-11:-4}  >
          <FormGroup  >
            {classifiedWhere.map((typeLevel,i)=>(
               <FormControlLabel 
               key={i}
               
               control={
               <Checkbox 
               name={typeLevel.name} 
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               
             />
               
               } label={typeLevel.label} />
            ))}
          
          </FormGroup>
           
          </Grid2>
          </Grid2>
          <Grid2 container size={4} >
          <Grid2 size={12}>
          <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              With What
              </Typography>
              </InputLabel>
          </Grid2>
          <Grid2  size={{xs:12}}  >
          <FormGroup  >
            {classifiedWithWhat.map((typeLevel,i)=>(
               <FormControlLabel 
               key={i}
               
               control={
               <Checkbox 
               name={typeLevel.name} 
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               onChange={(e)=>{
                
                if(typeLevel.name==="5"&&e.target.checked){
                  setClassifiedOther(true)
                }else{
                  setClassifiedOther(false)
                }
               }}
             />
               
               } label={typeLevel.label} />
            ))}
          
          </FormGroup>
           
          </Grid2>
          <Grid2 size={{xs:12}} sx={{display:classifiedWithWhereOther?"default":"none"}}  >
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          </Grid2>
          
        </Grid2>
        <Grid2 container size={12} spacing={"1rem"}>
          <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          OPERATIONAL INFORMATION
          </Typography>
          </Grid2>
          <Grid2 size={12}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Where
              </Typography>
              </InputLabel>
           </Grid2>
           <Grid2 container size={12} spacing={0} gap={0}>
          {oiWhere.map((typeLevel,i)=>(
            <Grid2 size={{xs:6,md:3}} key={i}  >
               <FormControlLabel 
               
               control={
               <Checkbox 
               name={typeLevel.name} 
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               onChange={(e)=>{
                
                if(typeLevel.label==="Other"&&e.target.checked){
                  setOIOthers(true)
                }else{
                  setOIOthers(false)
                }
               }}
             />
               
               } label={typeLevel.label} />
               </Grid2>
            ))}
            </Grid2>
            <Grid2 size={{xs:7}} sx={{display:oiOthers?"default":"none"}}  >
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
            
        </Grid2>
        <Grid2 container size={12} spacing={"1rem"}>
          <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          ADDITIONAL PPE
          </Typography>
          </Grid2>
          <Grid2 size={12}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Where
              </Typography>
              </InputLabel>
           </Grid2>
           <Grid2 container size={12} spacing={0} gap={0}>
          {apWhere.map((typeLevel,i)=>(
            <Grid2 size={{xs:6,md:3}} key={i}  >
               <FormControlLabel 
               
               control={
               <Checkbox 
               name={typeLevel.name} 
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               onChange={(e)=>{
                
                if(typeLevel.label==="Other"&&e.target.checked){
                  setOIOthers(true)
                }else{
                  setOIOthers(false)
                }
               }}
             />
               
               } label={typeLevel.label} />
               </Grid2>
            ))}
            </Grid2>
            <Grid2 size={{xs:12}}   >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Special clothing (specify)
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              multiline
              rows={2}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
            
        </Grid2>
        <Grid2 container size={12} spacing={"1rem"}>
        <Grid2 container size={5}>
          <Box sx={{width:"100%",display:"flex",flexDirection:"column",gap:"1rem"}}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          PRODUCT INVOLVED
          </Typography>
          </Grid2> 
          <Grid2 size={12}>
          <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Class
                  </Typography>
                </InputLabel>
                <Select
                size="small"
                defaultValue=""
                displayEmpty
                inputProps={{ "aria-label": "Technician" }}
                sx={{
                  borderRadius: commonCss.inputBorderRadius,
                  backgroundColor: commonCss.inputBackgroundColor,
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
                fullWidth
              >
                <MenuItem value="class1">Class 1</MenuItem>
                <MenuItem value="class2">Class 2</MenuItem>
                <MenuItem value="class3">Class 3</MenuItem>
              </Select>
          </Grid2>
          <Grid2 size={12}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            multiline
            rows={2}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white', 
                borderRadius:"13px"
              },
              '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
              },
            }}
          />
       
        </Grid2>
        </Box>
        </Grid2>
        <Grid2 container size={5}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          WORKING AREA
          </Typography>
          </Grid2> 
          <Grid2  size={{xs:12}}  >
          <FormGroup  >
            {workingArea.map((typeLevel,i)=>(
               <FormControlLabel 
               key={i}
               
               control={
               <Checkbox 
               name={typeLevel.name} 
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               onChange={(e)=>{
                
                if(typeLevel.label==="Others"&&e.target.checked){
                  setWorkAreaOthers(true)
                }else{
                  setWorkAreaOthers(false)
                }
               }}
             />
               
               } label={typeLevel.label} />
            ))}
          
          </FormGroup>
           
          </Grid2>
          <Grid2 size={12} sx={{display:workAreaOthers?"default":"none"}}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white', 
                borderRadius:"13px"
              },
              '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
              },
            }}
          />
       
        </Grid2>
        </Grid2>

        </Grid2>
        <Grid2 container size={12} spacing={"1rem"}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          OPERATIONAL MEASURES
          </Typography>
          </Grid2>
         
          <Grid2  size={4}>
            <Box sx={{width:"100%"}}>
              <Grid2 container size={12} spacing={"0.5rem"}>
              <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green}>
          MECHANICAL
          </Typography>
              </Grid2> 
            <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Equipment
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              ID/Tag Number
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2  size={{xs:12}}  >
          <FormGroup  >
            {mechnicalOM.map((typeLevel,i)=>(
               <FormControlLabel 
               key={i}
               
               control={
               <Checkbox 
               name={typeLevel.name} 
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
               onChange={(e)=>{
                
                if(typeLevel.label==="Others"&&e.target.checked){
                  setWorkAreaOthers(true)
                }else{
                  setWorkAreaOthers(false)
                }
               }}
             />
               
               } label={typeLevel.label} />
            ))}
          
          </FormGroup>
           
          </Grid2>
          <Grid2 size={{xs:12}}>
          <InputLabel>
                  <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                    Ventilation, specify
                  </Typography>
                </InputLabel>
                <TextField
            fullWidth
            variant="outlined"
            size="small"
            multiline
            rows={2}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white', 
                borderRadius:"13px"
              },
              '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
              },
            }}
          />
          </Grid2>
          <Grid2 size={12} >
          <InputLabel>
                <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                  See Isolation certificate
                </Typography>
              </InputLabel>
              <Typography
                variant="body2"
                fontWeight={commonCss.labelFontWeight} color={commonCss.green}
                 mt={1}
              >
                1. Isolation Certificate
              </Typography>
          </Grid2>
              </Grid2>
            </Box>
          </Grid2>
          <Grid2  size={4}>
            <Box sx={{width:"100%"}}>
              <Grid2 container size={12} spacing={"0.5rem"}>
              <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green}>
          ELECTRICAL
          </Typography>
              </Grid2> 
            <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Instrument
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              ID/Tag Number
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2  size={{xs:12}}  >
          <FormGroup  >
            {electricalOM.map((typeLevel,i)=>(
               <FormControlLabel 
               key={i}
               
               control={
               <Checkbox 
               name={typeLevel.name} 
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
              //  onChange={(e)=>{
                
              //   if(typeLevel.label==="Others"&&e.target.checked){
              //     setWorkAreaOthers(true)
              //   }else{
              //     setWorkAreaOthers(false)
              //   }
              //  }}
             />
               
               } label={typeLevel.label} />
            ))}
          
          </FormGroup>
           
          </Grid2>
          
          <Grid2 size={12} >
          <InputLabel>
                <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                  See Isolation certificate
                </Typography>
              </InputLabel>
              <Typography
                variant="body2"
                fontWeight={commonCss.labelFontWeight} color={commonCss.green}
                 mt={1}
              >
                1. Isolation Certificate
              </Typography>
          </Grid2>
              </Grid2>
            </Box>
          </Grid2>
          <Grid2  size={4}>
            <Box sx={{width:"100%"}}>
              <Grid2 container size={12} spacing={"0.5rem"}>
              <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.green}>
          INSTRUMENTATION:{"<"}24
          </Typography>
              </Grid2> 
            <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              Equipment/Electrical Motor (Specify):
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
              ID/Tag Number
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2  size={{xs:12}}  >
          <FormGroup  >
            {instrumentationOM.map((typeLevel,i)=>(
               <FormControlLabel 
               key={i}
               
               control={
               <Checkbox 
               name={typeLevel.name} 
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
              //  onChange={(e)=>{
                
              //   if(typeLevel.label==="Others"&&e.target.checked){
              //     setWorkAreaOthers(true)
              //   }else{
              //     setWorkAreaOthers(false)
              //   }
              //  }}
             />
               
               } label={typeLevel.label} />
            ))}
          
          </FormGroup>
           
          </Grid2>
          
          <Grid2 size={12} >
          <InputLabel>
                <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                  See Isolation certificate
                </Typography>
              </InputLabel>
              <Typography
                variant="body2"
                fontWeight={commonCss.labelFontWeight} color={commonCss.green}
                 mt={1}
              >
                1. Isolation Certificate
              </Typography>
          </Grid2>
              </Grid2>
            </Box>
          </Grid2>
        </Grid2>
        <Grid2 container size={12} spacing={"0.5rem"}>
        <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          WORKING AREA
          </Typography>
          </Grid2 >
          <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor}>
            Permit Holder is aware of the safety instructions as in the:
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor}>
                • Terminal safety manual
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body2" fontWeight={commonCss.normalSentenceWeight} color={commonCss.normalSenternceColor}>
                • Permit to Work and associated certificates
              </Typography>
            </ListItem>
          </List>
        </Grid2>
        <Grid2 container size={12} mb={2} mt={-1}>
        <Grid2 size={{xs:12,md:4}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Name
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              disabled={true}
              value={technician}
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Email
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Date and Time
              </Typography>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem>
                <DateTimePicker
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: commonCss.inputBackgroundColor, 
                      borderRadius:commonCss.inputBorderRadius
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                     borderColor: commonCss.inputBorderColor, 
                    },
                  }}
                  fullWidth
                />
              </DemoItem>
            </LocalizationProvider>
          </Grid2> 
        </Grid2>
          </Grid2>
          <Grid2 container size={12} spacing={"0.5rem"}>
          <Grid2 size={12}>
          <Typography variant="body2" fontWeight={commonCss.mainLabelHeading} color={commonCss.red}>
          Operational Measures: To be signed by Operation Executive (for MPP)
          or Controller (for KVDT)
          </Typography>
          </Grid2 >
          <Grid2  size={{xs:12}}  >
          <FormGroup  >
            {measureList.map((typeLevel,i)=>(
               <FormControlLabel 
               key={i}
               
               control={
               <Checkbox 
               name={typeLevel.name} 
               sx={{
                "& .MuiSvgIcon-root": {
                   color: "#00807F",
                 },
                 "&:checked": {
                   color: "#00807F",
                 },
               }}
              //  onChange={(e)=>{
                
              //   if(typeLevel.label==="Others"&&e.target.checked){
              //     setWorkAreaOthers(true)
              //   }else{
              //     setWorkAreaOthers(false)
              //   }
              //  }}
             />
               
               } label={typeLevel.label} />
            ))}
          
          </FormGroup>
           
          </Grid2>
          <Grid2 size={12} >
          <InputLabel>
                <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                 Any Other Measure
                </Typography>
              </InputLabel>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            multiline
            rows={2}
            sx={{
              '& .MuiOutlinedInput-root': {
                backgroundColor: 'white', 
                borderRadius:"13px"
              },
              '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: '747373', 
              },
            }}
          />
       
        </Grid2>
        <Grid2 container size={12}>
        <Grid2 size={{xs:12,md:4}} >
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Name
              </Typography>
            </InputLabel>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              
              sx={{
                '& .MuiOutlinedInput-root': {
                  backgroundColor: commonCss.inputBackgroundColor, 
                  borderRadius:commonCss.inputBorderRadius
                },
                '& .MuiOutlinedInput-notchedOutline': {
                 borderColor: commonCss.inputBorderColor, 
                },
              }}
              
            />
          </Grid2>
         
          <Grid2 size={{xs:12,md:4}}>
            <InputLabel sx={{pl:commonCss.labelPadding}}>
              <Typography variant="body2" fontWeight={commonCss.labelFontWeight} color={commonCss.labelInputColor}>
                Date and Time
              </Typography>
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem>
                <DateTimePicker
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                  slotProps={{
                    textField: {
                      size: "small",
                      variant: "outlined",
                    },
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      backgroundColor: commonCss.inputBackgroundColor, 
                      borderRadius:commonCss.inputBorderRadius
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                     borderColor: commonCss.inputBorderColor, 
                    },
                  }}
                  fullWidth
                />
              </DemoItem>
            </LocalizationProvider>
          </Grid2>
        </Grid2>
          </Grid2>
          {/* <Grid2 container size={12} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"10vw"
            }}
            // onClick={()=>{setLabour(true)}}
          >
           Next
          </Button>
          </Grid2> */}
          <Dialog
        open={certificateOpen}
        onClose={()=>{setCertificateOpen(false)}}
        PaperProps={{
          sx: {
            maxWidth: {xs:"100vw",md:"50vw"},
            width:{xs:"100vw",md:"55vw"}
          },
        }}
        children={
         <CertificateSelect handleCloseClick={()=>{
          setCertificateOpen(false)
          getData()
        }} data={data} />
        }
        />
         {/* <Dialog
        open={labour}
        onClose={()=>{setLabour(false)}}
        PaperProps={{
          sx: {
            maxWidth: {xs:"100vw",md:"50vw"},
            width:{xs:"100vw",md:"55vw"}
          },
        }}
        children={
         <LabourSelect handleCloseClick={()=>{setLabour(false)}}/>
        }
        /> */}
        
        <Dialog
        // maxWidth={"lg"}
  open={certificateListOpen.radiation}
  onClose={()=>{setCertificateListOpen({...certificateListOpen,radiation:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <RadiationCertificate handleCloseClick={()=>{setCertificateListOpen({...certificateListOpen,radiation:false})}}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={certificateListOpen.isolation}
  onClose={()=>{setCertificateListOpen({...certificateListOpen,isolation:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <IsolationCertificate certificateDetail={certificateInfo} handleCloseClick={()=>{setCertificateListOpen({...certificateListOpen,isolation:false})}}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={certificateListOpen.lifting}
  onClose={()=>{setCertificateListOpen({...certificateListOpen,lifting:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <LiftingAndHoisting handleCloseClick={()=>{setCertificateListOpen({...certificateListOpen,lifting:false})}}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={certificateListOpen.excavation}
  onClose={()=>{setCertificateListOpen({...certificateListOpen,excavation:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <Excavation dataInfo={dataInfo} handleCloseClick={()=>{setCertificateListOpen({...certificateListOpen,excavation:false})}}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={certificateListOpen.confinedSpace}
  onClose={()=>{setCertificateListOpen({...certificateListOpen,confinedSpace:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <ConfinedSpaceCertificate handleCloseClick={()=>{setCertificateListOpen({...certificateListOpen,confinedSpace:false})}}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={certificateListOpen.hotWork}
  onClose={()=>{setCertificateListOpen({...certificateListOpen,hotWork:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <HotWork certificateDetail={certificateInfo} handleCloseClick={()=>{setCertificateListOpen({...certificateListOpen,hotWork:false})}}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={certificateListOpen.safe}
  onClose={()=>{setCertificateListOpen({...certificateListOpen,safe:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <SafeOverride certificateDetail={certificateInfo} handleCloseClick={()=>{setCertificateListOpen({...certificateListOpen,safe:false})}}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={riskLevelBol.method}
  onClose={()=>{setRiskLevelBol({...riskLevelBol,method:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <MethodStatement dataInfo={dataInfo} handleCloseClick={()=>{setRiskLevelBol({...riskLevelBol,method:false})}}/>
  </DialogContent>
       </Dialog>
       <Dialog
        // maxWidth={"lg"}
  open={riskLevelBol.riskAssessment}
  onClose={()=>{setRiskLevelBol({...riskLevelBol,riskAssessment:false})}}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <RiskAssessment handleCloseClick={()=>{setRiskLevelBol({...riskLevelBol,riskAssessment:false})}}/>
  </DialogContent>
       </Dialog>
       
    </Box>
  );
};

export default PTWForm1;
