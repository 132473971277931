import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Paper,
  Button,
  Divider,
  InputLabel,
} from "@mui/material";
import { Grid2 } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { commonCss } from "../../../StyleConfig/CommonCss";

const RadiationCertificate = ({ handleCloseClick }) => {
  const [checked, setChecked] = useState({
    xray: false,
    other: false,
  });
  const [showFields, setShowFields] = useState(false);
  const [showFieldsforother, setShowFieldsOthers] = useState(false);

  const handleChange = (event) => {
    const isChecked = event.target.checked;
    setChecked({ xray: isChecked, other: false });
    setShowFields(isChecked);
    setShowFieldsOthers(false);
  };

  const handleChangeother = (event) => {
    const isChecked = event.target.checked;
    setChecked({ xray: false, other: isChecked });
    setShowFields(false);
    setShowFieldsOthers(isChecked);
  };

  return (
    <Grid2 container size={12} spacing={"1rem"}>
      <Grid2 container size={12} alignItems={"center"}>
        <Grid2 container size={4}>
          <Box
            sx={{
              backgroundColor: commonCss.shadeGreen,
              display: "flex",
              alignItems: "cente",
              p: 1,
              cursor: "pointer",
            }}
            onClick={handleCloseClick}
          >
            <ArrowBack color={commonCss.green} />
          </Box>
        </Grid2>
        <Grid2 container size={4} justifyContent={"center"}>
          <Typography variant="h5" fontWeight="bold" color="white">
            Radiation Certificate
          </Typography>
        </Grid2>
        <Grid2 container size={4} justifyContent={"flex-end"}>
          <Box
            sx={{
              backgroundColor: commonCss.shadeGreen,
              p: 1,
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              Permit To Work No. <span style={{ color: "#e53e3e" }}>2701</span>
            </Typography>
          </Box>
        </Grid2>
        <Divider
          sx={{ borderColor: commonCss.red, borderWidth: 1, width: "100%" }}
        />
      </Grid2>
      <Grid2 container size={12} alignItems={"center"}>
        <Typography color="white" variant="body2">
          Valid only for routine activities involving ionise radiation source
          (To be completed only by authorised persons)
        </Typography>
      </Grid2>
      <Grid2 container size={12} alignItems={"center"}>
        <Typography color="white" variant="body2">
          (To be completed only by authorised persons)
        </Typography>
      </Grid2>
      <Grid2 container size={12}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container spacing={2} mb={2}>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Radioactive material being used, specify
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Activity of material
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Contained within
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
          </Grid2>
          {/* <Grid2 container spacing={2}>
            <Typography
              variant="body2"
              fontWeight={commonCss.labelFontWeight}
              color={commonCss.labelInputColor}
            >
              Equipment
            </Typography>
          </Grid2> */}
          <Grid2 container spacing={2} mb={2}>
            <Grid2 size={{ xs: 12, md: 3 }}>
              <Grid2 item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked.xray}
                      onChange={handleChange}
                      sx={{
                        color: commonCss.green,
                        "&.Mui-checked": {
                          color: commonCss.green,
                        },
                      }}
                    />
                  }
                  label="X-ray machine"
                />
              </Grid2>
              <Grid2 item>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked.other}
                      onChange={handleChangeother}
                      sx={{
                        color: commonCss.green,
                        "&.Mui-checked": {
                          color: commonCss.green,
                        },
                      }}
                    />
                  }
                  label="Other equipment"
                />
              </Grid2>
            </Grid2>

            {showFields && (
              <>
                <Grid2 size={{ xs: 12, md: 5 }}>
                  <InputLabel sx={{ pl: commonCss.labelPadding }}>
                    <Typography
                      variant="body2"
                      fontWeight={commonCss.labelFontWeight}
                      color={commonCss.labelInputColor}
                    >
                      Manufacturer/Model
                    </Typography>
                  </InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: commonCss.inputBackgroundColor,
                        borderRadius: commonCss.inputBorderRadius,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 2 }}>
                  <InputLabel sx={{ pl: commonCss.labelPadding }}>
                    <Typography
                      variant="body2"
                      fontWeight={commonCss.labelFontWeight}
                      color={commonCss.labelInputColor}
                    >
                      Voltage
                    </Typography>
                  </InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="KV"
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: commonCss.inputBackgroundColor,
                        borderRadius: commonCss.inputBorderRadius,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 2 }}>
                  <InputLabel sx={{ pl: commonCss.labelPadding }}>
                    <Typography
                      variant="body2"
                      fontWeight={commonCss.labelFontWeight}
                      color={commonCss.labelInputColor}
                    >
                      Current
                    </Typography>
                  </InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="mA"
                    size="small"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: commonCss.inputBackgroundColor,
                        borderRadius: commonCss.inputBorderRadius,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    inputProps={{
                      style: { textAlign: "right" },
                    }}
                  />
                </Grid2>
              </>
            )}

            {showFieldsforother && (
              <Grid2 size={{ xs: 12, md: 6 }}>
                <Grid2 item xs={6} md={6}>
                  <InputLabel>
                    <Typography
                      variant="body2"
                      fontWeight="bold"
                      color="black"
                    ></Typography>
                  </InputLabel>
                  <TextField
                    fullWidth
                    variant="outlined"
                    multiline
                    size="small"
                    rows={2}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: commonCss.inputBackgroundColor,
                        borderRadius: commonCss.inputBorderRadius,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                  />
                </Grid2>
              </Grid2>
            )}
          </Grid2>
          <Grid2 container spacing={2} mb={2}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <InputLabel sx={{ pl: 0.5 }}>
                <Typography variant="body2" fontWeight="500" color="black">
                  Additional information about operation of equipment
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                size="small"
                rows={2}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>

            <Grid2 size={{ xs: 12, md: 6 }}>
              <InputLabel sx={{ pl: 0.5 }}>
                <Typography variant="body2" fontWeight="500" color="black">
                  Measures to prevent and mitigate exposure
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                size="small"
                rows={2}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
          </Grid2>

          <Grid2 container spacing={2} mb={2} alignItems={"flex-end"}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <InputLabel sx={{ pl: 0.5 }}>
                <Typography variant="body2" fontWeight="500" color="black">
                  Additional controls required
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                size="small"
                rows={2}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 container spacing={2} size={{xs:12,md:6}}>
              <Grid2 size={12}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Radiation equipment will be used between
                </Typography>
              </InputLabel>
              </Grid2>
          
          <Grid2 size={{ xs: 12, md: 6 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  From
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: commonCss.inputBackgroundColor,
                        borderRadius: commonCss.inputBorderRadius,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Until
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: commonCss.inputBackgroundColor,
                        borderRadius: commonCss.inputBorderRadius,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
          </Grid2>

          </Grid2>
          
        </Paper>
      </Grid2>
      <Grid2 container size={4}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"1rem"}>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.mainLabelHeading}
                color={commonCss.red}
                textAlign={"center"}
              >
                Engineer
              </Typography>
              <Divider
                sx={{
                  borderColor: commonCss.red,
                  borderWidth: 1,
                  width: "100%",
                  mt: 1,
                }}
              />
            </Grid2>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.normalSentenceWeight}
                color={commonCss.normalSenternceColor}
                sx={{ textTransform: "none" }}
              >
                I certify that I have reviewed the proposed work and I am
                satisfied that it is adequately described on the permit to work
                and that the precautions detailed are adequate and clearly
                defined
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Name
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={4}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"1rem"}>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.mainLabelHeading}
                color={commonCss.red}
                textAlign={"center"}
              >
                LPI
              </Typography>
              <Divider
                sx={{
                  borderColor: commonCss.red,
                  borderWidth: 1,
                  width: "100%",
                  mt: 1,
                }}
              />
            </Grid2>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.normalSentenceWeight}
                color={commonCss.normalSenternceColor}
                sx={{ textTransform: "none" }}
              >
                Radiation is described in the permit to work is allowed between:
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Name
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  From
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Until
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={4}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"1rem"}>
            <Grid2 item xs={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.normalSentenceWeight}
                color={commonCss.normalSenternceColor}
                sx={{ textTransform: "none" }}
              >
                Radiation certificate is certificate and permit to work are
                withdrawn:
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Name
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Reason
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                multiline
                minRows={2}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={12} justifyContent={"flex-end"}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: commonCss.green,
            borderRadius: commonCss.buttonBorderRadius,
            textTransform: "none",
            padding: "0.6rem 1rem",
            color: commonCss.buttonTextColor,
            width: "10vw",
          }}
        >
          Submit
        </Button>
      </Grid2>
    </Grid2>
  );
};

export default RadiationCertificate;
