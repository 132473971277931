import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import PTWForm2 from './PTWForm2';
import PTWForm1 from './PTWForm1';
import { commonCss } from '../../StyleConfig/CommonCss';
import { useSearchParams } from 'react-router-dom';
import { api } from '../API/config';
import { axiosPrivate } from '../../axios/axios';
import Loading from '../../Component/Loading';

const steps = [
  {
    label: 'Select campaign settings',
    description: `For each ad campaign that you create, you can control how much
              you're willing to spend on clicks and conversions, which networks
              and geographical locations you want your ads to show on, and more.`,
  },
  {
    label: 'Create an ad group',
    description:
      'An ad group contains one or more ads which target a shared set of keywords.',
  },
  {
    label: 'Create an ad',
    description: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
  },
];

export default function MainForm() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = 2;

  const [searchParams,setSearchParams]=useSearchParams()
  const [data,setData]=useState({})
  const [loading,setLoading]=useState(false)

  useEffect(()=>{
    getData()
  },[])
   
  const getData=async()=>{
    setLoading(true)
    const getDetailByid=api.PTWForm.getDetailById
    await axiosPrivate.get(`${getDetailByid}/${searchParams.get("id")}`).then(res=>{
      setLoading(false)
      setData(res.data.data)
      console.log(res)
    }).catch(e=>{
      setLoading(false)
      setData({})
      console.log(e,"MainForm")
    })
  }
  

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleSubmit=()=>{
    
  }

  return (
    <>
    {loading?<Loading/>:
    <Paper sx={{background: 'linear-gradient(to right, #DAF2F2, #FEDADA)',
        borderRadius: "10px",
        p:1.5,}} >
      {
        activeStep===0?<PTWForm1 data={data} getData={getData}/>:<PTWForm2 handleSubmit={handleSubmit} data={data} />
      }
      
      <MobileStepper
        variant="dots"
        steps={2}
        position="static"
        activeStep={activeStep}
        sx={{backgroundColor: "transparent", "& .MuiMobileStepper-dotActive":{
            backgroundColor: commonCss.green
        }}}
        nextButton={
        <>
        {activeStep!==1?
          <Button
            size="small" 
            variant='contained'
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            sx={{
                backgroundColor:commonCss.green,
                borderRadius: commonCss.buttonBorderRadius ,
                textTransform: "none", 
                padding: "0.6rem 1rem", 
                color:commonCss.buttonTextColor,
                width:"10vw"
            }}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
          :
          <Button
            size="small" 
            variant='contained'
            onClick={handleSubmit}
            
            sx={{
                backgroundColor:commonCss.green,
                borderRadius: commonCss.buttonBorderRadius ,
                textTransform: "none", 
                padding: "0.6rem 1rem", 
                color:commonCss.buttonTextColor,
                width:"10vw"
            }}
          >
           Submit
          </Button>}
          </>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}
          variant="contained"
          sx={{
            backgroundColor:commonCss.green,
            borderRadius: commonCss.buttonBorderRadius ,
            textTransform: "none", 
            padding: "0.6rem 1rem", 
            color:commonCss.buttonTextColor,
            width:"10vw"
          }}
          >
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Paper>
    }
    </>
  );
}
