import { Box, Card, Dialog, DialogContent, Grid2, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TableContainerComp from "../../Component/TableContainer";
import { Link } from "react-router-dom";
import { Beenhere, Check, NotInterestedOutlined, RateReview } from "@mui/icons-material";
import { commonCss } from "../../StyleConfig/CommonCss";
import { api } from "../API/config";
import { axiosPrivate } from "../../axios/axios";
import moment from "moment";
import NewForm from "./NewForm";

const FormsList = () => {
  const [data, setData] = useState([]);
  const [newForm,setNewForm]=useState(false)

  useEffect(() => {
    // setData([
    //   {
    //     no: "67895",
    //     status:"Progress",
    //     name:"Kumar",
    //     date:"12/3/2024",
    //     location:"Location 1"
    //   },
    //   {
    //     no: "98763",
    //     status:"New",
    //     name:"Lukesh",
    //     date:"02/4/2024",
    //     location:"Location 3"
    //   },
    //   {
    //     no: "45685",
    //     status:"Progress",
    //     name:"Sai",
    //     date:"12/12/2024",
    //     location:"Location 1"
    //   },
    //   {
    //     no: "23557",
    //     status:"New",
    //     name:"Surya",
    //     date:"14/3/2024",
    //     location:"Location 4"
    //   },
    //   {
    //     no: "65656",
    //     status:"New",
    //     name:"Siva",
    //     date:"05/11/2023",
    //     location:"Location 2"
    //   },
    //   {
    //     no: "86548",
    //     status:"New",
    //     name:"Krish",
    //     date:"18/3/2024",
    //     location:"Location 3"
    //   },
    // ]);
     getData()
  }, []);

  const getData=async()=>{
    const getForm=api.PTWForm.getAllForm
   await axiosPrivate.get(`${getForm}`).then(res=>{
       console.log(res)
       setData(res.data.data)
    }).catch(e=>{
       console.log(e)
    })
  }

  const columns = [
    {
      Header: "Form No",
      accessor: "formNo",
      Cell: ({ cell }) => {
        return (
          <Link
            to={`/ptwform?id=${cell.row.original.id}&no=${cell.value}`}
            style={{
              color: "#FD0000",
              textDecorationLine: "underline",
              textDecorationColor:commonCss.green,
              fontWeight: 800,
            }}
          >
            {cell.value}
          </Link>
        );
      },
    },
    {
      Header: "Contractor",
      accessor: "contractor",
    },
    {
      Header: "Date",
      accessor: "issueDate",
      Cell:({cell})=>{
        return(
            <Typography sx={{fontSize:"inherit",fontWeight:"inherit",color:"inherit"}}>
                {cell.value!==null?moment(cell.value).format("DD/MM/YYYY"):null}
            </Typography>
        )
    }
    },
    {
      Header: "Location",
      accessor: "location",
    },
    {
      Header: "Status",
      accessor: "formStatusText",
    },

  ];

  const newButton=()=>{
    setNewForm(true)
  }

  return (
    <Box sx={{display:"flex",flexDirection:'column'}}>
      <Grid2 container size={12} mb={"1rem"} spacing={"1rem"}>
         <Grid2 size={3}>
        <Card sx={{
          wordWrap: "break-word",
          backgroundColor: "#ffbe0b",
          padding: "0.8rem 1rem",
          cursor:"pointer",
          // height:"12vh"
       
        }}>
        
        <Box sx={{display:"flex",justifyContent:"space-between"}}>
          <Box>
          <Typography
              sx={{
                wordWrap: "break-word",
                fontSize: "0.9rem",
                fontWeight: 800,
                color: "white",
                textTransform: "uppercase",
                letterSpacing: "0.3px",
              }}
            >
              New
            </Typography>
            <Typography
             
             sx={{
              fontWeight: 600,
              color: "white",
              fontSize:"1.7rem",
              mt:"0.5rem"
            }}
            >
              2
             
            </Typography>
          </Box>
          <Box >
          <RateReview  sx={{fontSize:"4rem",color:"white"}} />
          </Box>
        </Box>
        </Card>
        </Grid2>
        <Grid2 size={3}>
        <Card sx={{
          wordWrap: "break-word",
          backgroundColor: "#25c2e3",
          padding: "0.8rem 1rem",
          cursor:"pointer",
          // height:"12vh"
       
        }}>
        
        <Box sx={{display:"flex",justifyContent:"space-between"}}>
          <Box>
          <Typography
              sx={{
                wordWrap: "break-word",
                fontSize: "0.9rem",
                fontWeight: 800,
                color: "white",
                textTransform: "uppercase",
                letterSpacing: "0.3px",
              }}
            >
              Progress
            </Typography>
            <Typography
             
             sx={{
              fontWeight: 600,
              color: "white",
              fontSize:"1.7rem",
              mt:"0.5rem"
            }}
            >
              5
             
            </Typography>
          </Box>
          <Box >
          <Check  sx={{fontSize:"4rem",color:"white"}} />
          </Box>
        </Box>
        </Card>
        </Grid2>
        <Grid2 size={3}>
        <Card sx={{
          wordWrap: "break-word",
          backgroundColor: "#ff5c75",
          padding: "0.8rem 1rem",
          cursor:"pointer",
          // height:"12vh"
       
        }}>
        
        <Box sx={{display:"flex",justifyContent:"space-between"}}>
          <Box>
          <Typography
              sx={{
                wordWrap: "break-word",
                fontSize: "0.9rem",
                fontWeight: 800,
                color: "white",
                textTransform: "uppercase",
                letterSpacing: "0.3px",
              }}
            >
              Rejected
            </Typography>
            <Typography
             
             sx={{
              fontWeight: 600,
              color: "white",
              fontSize:"1.7rem",
              mt:"0.5rem"
            }}
            >
              3
             
            </Typography>
          </Box>
          <Box >
          <NotInterestedOutlined  sx={{fontSize:"4rem",color:"white"}} />
          </Box>
        </Box>
        </Card>
        </Grid2>
        <Grid2 size={3}>
        <Card sx={{
          wordWrap: "break-word",
          backgroundColor: "#43d39e",
          padding: "0.8rem 1rem",
          cursor:"pointer",
          // height:"12vh"
       
        }}>
        
        <Box sx={{display:"flex",justifyContent:"space-between"}}>
          <Box>
          <Typography
              sx={{
                wordWrap: "break-word",
                fontSize: "0.9rem",
                fontWeight: 800,
                color: "white",
                textTransform: "uppercase",
                letterSpacing: "0.3px",
              }}
            >
              Approved
            </Typography>
            <Typography
             
             sx={{
              fontWeight: 600,
              color: "white",
              fontSize:"1.7rem",
              mt:"0.5rem"
            }}
            >
              8
             
            </Typography>
          </Box>
          <Box >
          <Beenhere  sx={{fontSize:"4rem",color:"white"}} />
          </Box>
        </Box>
        </Card>
        </Grid2>
      </Grid2>
      {/* <Typography
        sx={{
          color: "White",
          fontSize: "1.5rem",
          fontWeight: 600,
          mb: "1rem",
        }}
      >
        PTW Forms
      </Typography> */}
       
     
        <TableContainerComp 
        columns={columns} 
        data={data} 
        formListMain={true} 
        buttonNew={newButton}
        />

<Dialog
        // maxWidth={"lg"}
  open={newForm}
  onClose={()=>{
    setNewForm(false)
    getData()
  }}
  PaperProps={{
    sx: {
      maxWidth: {xs:"100vw",md:"80vw",lg:"70vw"},
      width:{xs:"100vw",md:"80vw",lg:"70vw"},
      background:commonCss.normalLinearGradient,
      borderRadius:"10px"
    },
  }}
>
  <DialogContent >
  <NewForm handleCloseClick={()=>{
    setNewForm(false)
    getData()
    }}/>
  </DialogContent>
       </Dialog>
      
    </Box>
  );
};

export default FormsList;
