import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Select,
  Divider,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Button,
  Paper,
  Radio,
} from "@mui/material";
import { Grid2 } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import { commonCss } from "../../../StyleConfig/CommonCss";
import TableSample from "../../../Component/TableSample";
import { ArrowBack } from "@mui/icons-material";
import moment from "moment";
import dayjs from "dayjs";
import NormalTableWithRadius from "../../../Component/NormalTableWithRadius";
import { Form, Formik } from "formik";
import Loading from "../../../Component/Loading";
import { useSelector } from "react-redux";
import { api } from "../../API/config";
import { axiosPrivate } from "../../../axios/axios";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";

const IsolationCertificate = ({certificateDetail,handleCloseClick }) => {
  const login = useSelector((state) => state.auth.loginInfo);
  const [searchParams,setSearchParams]=useSearchParams()
  const [typeMeasure,setTypeMeasure]=useState([])
  const [checked, setChecked] = useState({
    electrical: certificateDetail.electricalDetails!==null?true:false,
    mechanical: certificateDetail.mechanicalDetails!==null?true:false,
  });
  const [certificateInfo,setCertificateInfo]=useState({})
  const [approveInfo,setApproveInfo]=useState({
    engineerName:certificateInfo.engineerName,
    lpiName:certificateInfo.lpiName,
    certificateClosedName:certificateInfo.certificateClosedName
  })
  const [loading,setLoading]=useState(false)
  
  const [isolationData,setIsolationData]=useState([
    {
      rowName:"Isolation Owner",
      Name:"",
      company:"",
      date:null
    },
    {
      rowName:"Isolation Verifier",
      Name:"",
      company:"",
      date:null
    },
  ])
  const [reconnectionData,setReconnectionData]=useState([
    {
      rowName:"Isolation Owner",
      Name:"",
      date:null
    },
    {
      rowName:"Isolation Verifier",
      Name:"",
      date:null
    },
  ])

  useEffect(()=>{
    getData()
  },[])

  const getData=async()=>{
    const getByKey=api.attributeValue.getByKeyName
    await axiosPrivate.get(`${getByKey}?keyName=SpecifyEnergyIsolated`).then(res=>{
      setTypeMeasure(res.data.data)
    }).catch(e=>{
      console.log(e)
    })
    const getById=api.isolation.getById
    await axiosPrivate.get(`${getById}/${certificateDetail.id}`).then(res=>{
      console.log(res)
      setCertificateInfo(res.data.data)
    }).catch(e=>{
      console.log(e)
    })
  }


  const initialValue={
    id: certificateInfo.id,
    formId: certificateInfo.formId,
  lotoNo: certificateInfo.lotoNo,
  isolatedEquipment: certificateInfo.isolatedEquipment,
  locationId: certificateInfo.locationId,
  specifyEnergyIsolatedId: certificateInfo.specifyEnergyIsolatedId,
  // isolationOwnerId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  // isolationVerifierId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  electricalDetails: certificateInfo.electricalDetails,
  mechanicalDetails: certificateInfo.mechanicalDetails,
  engineerId: certificateInfo.engineerId,
  isEngineerApproved: certificateInfo.isEngineerApproved,
  engineerApprovedDate: certificateInfo.engineerApprovedDate,
  isCertificateClosed: certificateInfo.isCertificateClosed,
  certificateClosedBy: certificateInfo.certificateClosedBy,
  certificateClosedAt: certificateInfo.certificateClosedAt,
  certificateClosedReason: certificateInfo.certificateClosedReason,
  lpiId: certificateInfo.lpiId,
  isLPIApproved: certificateInfo.isLPIApproved,
  lpiApprovedDate: certificateInfo.lpiApprovedDate,
  certificateValidFrom: certificateInfo.certificateValidFrom,
  certificateValidTo: certificateInfo.certificateValidTo,
  // reconnectionPermitIssuerId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  // reconnectionPermitIssuedAt: "2024-10-15T05:23:08.451Z",
  // reconnectionPermitVerifierId: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  // reconnectionPermitVerifiedAt: "2024-10-15T05:23:08.451Z"
    }

 

 const onSubmit=async(value)=>{
  console.log(value)
  const editLink=api.isolation.edit
  await axiosPrivate.put(`${editLink}/${certificateInfo.id}`,value).then(res=>{
    toast.success("Successfully Updated")
  }).catch(e=>{
    console.log(e)
  })
 }

  const handleIsolation =(value,dataDetail,name)=>{

  }
  const handleReconnection=(value,dataDetail,name)=>{

  }
 
  
  const isolationColumns = [
    {
      
      accessor: "rowName",
      Cell: ({ cell }) => {
       return (
        <Typography sx={{fontSize:"15px",fontWeight:700}}>
          {cell.value}
        </Typography>
       )
      },
    },
     {
      Header: "Name",
      accessor: "name",
      Cell:({cell})=>{
        const [value,setValue]=useState(cell.value)
        return(
          <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={value}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
                onChange={(e) => {
                  setValue(e.target.value)
                  cell.row.original.name = e.target.value;
                }}
                onBlur={(e) =>
                  handleIsolation(
                    e.target.value,
                    cell.row.original,
                    "name"
                  )
                }
              />
        )
      }

    },
    {
      Header: "Company",
      accessor: "companhy",
      Cell:({cell})=>{
        const [value,setValue]=useState(cell.value)
        return(
          <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={value}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
                onChange={(e) => {
                  setValue(e.target.value)
                  cell.row.original.company = e.target.value;
                }}
                onBlur={(e) =>
                  handleIsolation(
                    e.target.value,
                    cell.row.original,
                    "company"
                  )
                }
              />
        )
      }
    },
    {
      Header: "Date/Time",
      accessor: "date",
      Cell:({cell})=>{
        const [value,setValue]=useState(cell.value)
        return(
          <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DemoItem>
      <DateTimePicker
        ampm={false}
        format="DD/MM/YYYY HH:mm"
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
        slotProps={{
          textField: {
            size: "small",
            variant: "outlined",
          },
        }}
        value={cell.value!==null ? dayjs(cell.value) : null}
        onChange={(newValue) => {
          if (newValue !== null) {
            if (`${newValue.$d}` !== "Invalid Date") {
              const dateFrom = moment(newValue.$d).format(
                "YYYY-MM-DD HH:MM "
              );
             
              setValue(dateFrom)
              cell.row.original.date = dateFrom;
        }}
        
      }}
      onBlur={(e) =>
        handleIsolation(
          value,
          cell.row.original,
          "date"
        )
      }

        sx={{
          "& .MuiOutlinedInput-root": {
            backgroundColor: commonCss.inputBackgroundColor,
            borderRadius: commonCss.inputBorderRadius,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: commonCss.inputBorderColor,
          },
        }}
        fullWidth
      />
    </DemoItem>
  </LocalizationProvider>
        )
      }
    }
  ];

  const reconnectionColumns = [
    {
      
      accessor: "rowName",
      Cell: ({ cell }) => {
       return (
        <Typography sx={{fontSize:"15px",fontWeight:700}}>
          {cell.value}
        </Typography>
       )
      },
    },
     {
      Header: "Name",
      accessor: "name",
      Cell:({cell})=>{
        const [value,setValue]=useState(cell.value)
        return(
          <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={value}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
                onChange={(e) => {
                  setValue(e.target.value)
                  cell.row.original.name = e.target.value;
                }}
                onBlur={(e) =>
                  handleReconnection(
                    e.target.value,
                    cell.row.original,
                    "name"
                  )
                }
              />
        )
      }

    },
    {
      Header: "Date/Time",
      accessor: "date",
      Cell:({cell})=>{
        const [value,setValue]=useState(cell.value)
        return(
          <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DemoItem>
      <DateTimePicker
        ampm={false}
        format="DD/MM/YYYY HH:mm"
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
          seconds: renderTimeViewClock,
        }}
        slotProps={{
          textField: {
            size: "small",
            variant: "outlined",
          },
        }}
        value={cell.value!==null ? dayjs(cell.value) : null}
        onChange={(newValue) => {
          if (newValue !== null) {
            if (`${newValue.$d}` !== "Invalid Date") {
              const dateFrom = moment(newValue.$d).format(
                "YYYY-MM-DD HH:MM "
              );
             
              setValue(dateFrom)
              cell.row.original.date = dateFrom;
        }}
        
      }}
      onBlur={(e) =>
        handleReconnection(
          value,
          cell.row.original,
          "date"
        )
      }

        sx={{
          "& .MuiOutlinedInput-root": {
            backgroundColor: commonCss.inputBackgroundColor,
            borderRadius: commonCss.inputBorderRadius,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: commonCss.inputBorderColor,
          },
        }}
        fullWidth
      />
    </DemoItem>
  </LocalizationProvider>
        )
      }
    }
  ];
  return (
    <>
    {!loading?
    <Formik initialValues={initialValue} onSubmit={onSubmit} enableReinitialize>
        {({ handleChange, setFieldValue, values, resetForm }) => (
          <Form>
    <Grid2 container size={12} spacing={"1rem"}>
      <Grid2 container size={12} alignItems={"center"}>
        <Grid2 container size={4}>
          <Box
            sx={{
              backgroundColor: commonCss.shadeGreen,
              display: "flex",
              alignItems: "cente",
              p: 1,
              cursor: "pointer",
            }}
            onClick={handleCloseClick}
          >
            <ArrowBack color={commonCss.green} />
          </Box>
        </Grid2>
        <Grid2 container size={4} justifyContent={"center"}>
          <Typography variant="h5" fontWeight="bold" color="white">
            Isolation Certificate-Lock out/Tag out
          </Typography>
        </Grid2>
        <Grid2 container size={4} justifyContent={"flex-end"}>
          <Box
            sx={{
              backgroundColor: commonCss.shadeGreen,
              p: 1,
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              Permit To Work No. <span style={{ color: "#e53e3e" }}>{searchParams.get("no")}</span>
            </Typography>
          </Box>
        </Grid2>
        <Divider
          sx={{ borderColor: commonCss.red, borderWidth: 1, width: "100%" }}
        />
      </Grid2>
      <Grid2 container size={12} alignItems={"center"}>
        <Typography color="white" variant="body2">
          (To be completed only by authorised persons)
        </Typography>
      </Grid2>
      <Grid2 container size={12}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container spacing={2} mb={2}>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Identify number(LOTO number)
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="lotoNo"
                value={values.lotoNo}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Isolated Equipment
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="isolatedEquipment"
                value={values.isolatedEquipment}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Location
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                name="locationId"
                value={values.locationId}
                onChange={handleChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Operational Measure Types
                </Typography>
              </InputLabel>
              <Select
                size="small"
                defaultValue=""
                displayEmpty
                value={values.specifyEnergyIsolatedId}
                inputProps={{ "aria-label": "Technician" }}
                sx={{
                  borderRadius: "13px",
                  backgroundColor: "white",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "747373",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
                fullWidth
                onChange={handleChange}
              >
                {JSON.stringify(typeMeasure)}
                {typeMeasure.map((measure,i)=>(
                  <MenuItem key={i} value={measure.id}>{measure.name}</MenuItem>
                ))}
              </Select>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={12}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container spacing={2}>
            <Grid2 size={12}>
            <Typography variant="body2" color={commonCss.red} fontWeight={commonCss.mainLabelHeading} >
              PERSON(S) COMPLETING THE ISOLATION
            </Typography>
            </Grid2>
            <Grid2 size={12} >
            <NormalTableWithRadius data={isolationData} columns={isolationColumns}/>
          </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={12}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container spacing={2} mb={2}>
            <Typography
              variant="body2"
              fontWeight={commonCss.labelFontWeight}
              color={commonCss.labelInputColor}
            >
              I have personally checked the equipment to be worked on under this
              Isolation certificate and certify it to have been isolated.
              Accidental reconnection of this equipment has been prevented by
              the following means:
            </Typography>
          </Grid2>

          <Grid2 container  mb={2}>
            <Grid2 container size={{ xs: 12, md: 4 }} spacing={2} >
            <FormControlLabel
              control={
                <Radio
                  checked={checked.electrical}
                  onChange={(e)=>{setChecked({electrical:true,mechanical:false})}}
                  sx={{
                    color: commonCss.green,
                    "&.Mui-checked": {
                      color: commonCss.green,
                    },
                  }}
                />
              }
              label="Electrical"
            />
            <FormControlLabel
              control={
                <Radio
                  checked={checked.mechanical}
                  onChange={(e)=>{setChecked({electrical:false,mechanical:true})}}
                  sx={{
                    color: commonCss.green,
                    "&.Mui-checked": {
                      color: commonCss.green,
                    },
                  }}
                />
              }
              label="Mechanical"
            />
            </Grid2>
            
            {checked.electrical && (
              <Grid2 size={{ xs: 12, md: 8 }}>
                {/* <InputLabel>
                  <Typography variant="body2" fontWeight="bold" color="black">
                    Electrical
                  </Typography>
                </InputLabel> */}
                <TextField
                  variant="outlined"
                  size="small"
                  value={values.electricalDetails}
                  onChange={handleChange}
                  multiline
                  minRows={2}
                  maxRows={5}
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: commonCss.inputBackgroundColor,
                      borderRadius: commonCss.inputBorderRadius,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: commonCss.inputBorderColor,
                    },
                  }}
                />
              </Grid2>
            )}

            {checked.mechanical && (
              <Grid2 size={{ xs: 12, md: 8 }}>
                {/* <InputLabel>
                  <Typography variant="body2" fontWeight="bold" color="black">
                    Mechanical
                  </Typography>
                </InputLabel> */}
                <TextField
                  variant="outlined"
                  size="small"
                  value={values.mechanicalDetails}
                  onChange={handleChange}
                  multiline
                  minRows={2}
                  maxRows={5}
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: commonCss.inputBackgroundColor,
                      borderRadius: commonCss.inputBorderRadius,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: commonCss.inputBorderColor,
                    },
                   
                  }}
                />
              </Grid2>
            )}
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={4}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"1rem"}>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.mainLabelHeading}
                color={commonCss.red}
                textAlign={"center"}
              >
                Engineer
              </Typography>
              <Divider
                sx={{
                  borderColor: commonCss.red,
                  borderWidth: 1,
                  width: "100%",
                  mt: 1,
                }}
              />
            </Grid2>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.normalSentenceWeight}
                color={commonCss.normalSenternceColor}
                sx={{ textTransform: "none" }}
              >
                I certify that I have reviewed the proposed work and I am
                satisfied that it is adequately described on the permit to work
                and that the precautions detailed are adequate and clearly
                defined
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Name
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={approveInfo.engineerName}
                slotProps={{
                  input: {
                    readOnly: true, // Set readOnly to true
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    value={values.isEngineerApproved?dayjs(values.engineerApprovedDate):null}
                    readOnly
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 container size={12} justifyContent={"center"}>
            <Button
            variant="contained"
            disabled={values.isEngineerApproved}
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={()=>{
              setFieldValue("isEngineerApproved",true)
              setFieldValue("engineerId",login.userId)
              setFieldValue("engineerApprovedDate",moment().format("YYYY-MM-DDTHH:mm:ss"))
              setApproveInfo({
                ...approveInfo,
                engineerName:login.firstName+" "+login.lastName
              })
            }}
          >
            Approve
          </Button>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={4}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"1rem"}>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.mainLabelHeading}
                color={commonCss.red}
                textAlign={"center"}
              >
                LPI
              </Typography>
              <Divider
                sx={{
                  borderColor: commonCss.red,
                  borderWidth: 1,
                  width: "100%",
                  mt: 1,
                }}
              />
            </Grid2>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.normalSentenceWeight}
                color={commonCss.normalSenternceColor}
                sx={{ textTransform: "none" }}
              >
                Confined Space is described in the permit to work is allowed
                between:
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Name
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={approveInfo.lpiName}
                slotProps={{
                  input: {
                    readOnly: true, // Set readOnly to true
                  },
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    readOnly
                    value={values.isLPIApproved?dayjs(values.lpiApprovedDate):null}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  From
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    value={values.certificateValidFrom!==null?dayjs(values.certificateValidFrom):null}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    onChange={(newValue) => {
                      console.log(newValue);
                      if (newValue !== null) {
                        if (`${newValue.$d}` !== "Invalid Date") {
                          const dateFrom = moment(newValue.$d).format(
                            "YYYY-MM-DDTHH:mm:ss"
                          );
                          setFieldValue("certificateValidFrom",dateFrom)
                          
                          // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                          // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                          // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                          // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                          // setFieldValue("installationDate", dateFormat);
                        }
                      }
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Until
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    value={values.certificateValidTo!==null?dayjs(values.certificateValidTo):null}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    onChange={(newValue) => {
                      console.log(newValue);
                      if (newValue !== null) {
                        if (`${newValue.$d}` !== "Invalid Date") {
                          const dateFrom = moment(newValue.$d).format(
                            "YYYY-MM-DDTHH:mm:ss"
                          );
                          setFieldValue("certificateValidTo",dateFrom)
                          
                          // const hour=(new Date().getHours())<10?"0"+new Date().getHours():new Date().getHours()
                          // const min=(new Date().getMinutes())<10?"0"+new Date().getMinutes():new Date().getMinutes()
                          // const sec=(new Date().getSeconds())<10?"0"+new Date().getSeconds():new Date().getSeconds()
                          // const dateFormat=dateFrom+"T"+hour+":"+min+":"+sec
                          // setFieldValue("installationDate", dateFormat);
                        }
                      }
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 container size={12} justifyContent={"center"}>
            <Button
            variant="contained"
            disabled={values.isLPIApproved}
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={()=>{
              setFieldValue("isLPIApproved",true)
              setFieldValue("lpiId",login.userId)
              setFieldValue("lpiApprovedDate",moment().format("YYYY-MM-DDTHH:mm:ss"))
              setApproveInfo({
                ...approveInfo,
                lpiName:login.firstName+" "+login.lastName
              })
            }}
          >
            Approve
          </Button>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={4}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"1rem"}>
            <Grid2 item xs={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.normalSentenceWeight}
                color={commonCss.normalSenternceColor}
                sx={{ textTransform: "none" }}
              >
                Isolation certificate is certificate and permit to work are
                withdrawn:
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Name
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                slotProps={{
                  input: {
                    readOnly: true,
                  },
                }}
                value={values.isCertificateClosed!==null?approveInfo.certificateClosedName:null}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    value={values.isCertificateClosed?dayjs(values.certificateClosedAt):null}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Reason
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={values.certificateClosedReason}
                onChange={handleChange}
                multiline
                minRows={2}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 container size={12} justifyContent={"center"}>
            <Button
            variant="contained"
            disabled={values.isCertificateClosed}
            sx={{
              backgroundColor:commonCss.red,
              borderRadius: commonCss.buttonBorderRadius ,
              textTransform: "none", 
              padding: "0.6rem 1rem", 
              color:commonCss.buttonTextColor,
              width:"15vw"
            }}
            onClick={()=>{
              setFieldValue("isCertificateClosed",true)
              setFieldValue("certificateClosedBy",login.userId)
              setFieldValue("certificateClosedAt",moment().format("YYYY-MM-DDTHH:mm:ss"))
              setApproveInfo({
                ...approveInfo,
                certificateClosedName:login.firstName+" "+login.lastName
              })
            }}
          >
            Close
          </Button>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={12}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container spacing={2} mb={2}>
            <Button
              variant="contained"
              color="error"
              sx={{
                width: "100%",
                backgroundColor: "#FF0000",
                color: "white",
                borderRadius: "10px",
                "&:hover": {
                  backgroundColor: "#cc0000",
                },
                mt: 2,
              }}
            >
              Reconnection
            </Button>
          </Grid2>
          <Grid2 container spacing={2} mb={2}>
            <Typography
              variant="body2"
              fontWeight={commonCss.normalSentenceWeight}
              color={commonCss.normalSenternceColor}
              sx={{ textTransform: "none" }}
            >
              I have personally checked the equipment isolated above and confirm
              that work on it is completed and said equipment has been
              re-connected and is in a safe working condition.
            </Typography>
          </Grid2>
          <Grid2 size={12} >
            <NormalTableWithRadius data={reconnectionData} columns={reconnectionColumns}/>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={12} justifyContent={"flex-end"}>
        <Button
          variant="contained"
          type="submit"
          sx={{
            backgroundColor: commonCss.green,
            borderRadius: commonCss.buttonBorderRadius,
            textTransform: "none",
            padding: "0.6rem 1rem",
            color: commonCss.buttonTextColor,
            width: "10vw",
          }}
        >
          Submit
        </Button>
      </Grid2>
    </Grid2>
    </Form>
        )}

        </Formik>
        :<Loading/>}
        </>
  );
};

export default IsolationCertificate;
