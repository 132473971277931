import {
  Button,
  Divider,
  Grid2,
  Paper,
  Typography,
  TextField,
  Select,
  MenuItem,
  Checkbox,
  InputLabel,
  FormGroup,
  FormControlLabel,
  IconButton,
  Box,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { commonCss } from "../../../StyleConfig/CommonCss";
import { ArrowBack, FileUploadOutlined } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import TableSample from "../../../Component/TableSample";
import NormalTableWithRadius from "../../../Component/NormalTableWithRadius";
import { EditOutlined } from "@mui/icons-material";

const ConfinedSpaceCertificate = ({ handleCloseClick }) => {
  const [measureData, setMeasureData] = useState([
    {
      rowName: "Oxygen",
      MOM: "",
      result: "",
    },
    {
      rowName: "Explosive vapour/gas, specify",
      MOM: "",
      result: "",
    },
    {
      rowName: "Asphyxiant vapour/gas, specify",
      MOM: "",
      result: "",
    },
    {
      rowName: "Poisonous vapour/gas, specify",
      MOM: "",
      result: "",
    },
  ]);
  const classifiedWhere = [
    {
      value: "",
      name: "1",
      label: "Fresh air breathing set",
    },
    {
      value: "",
      name: "2",
      label: "Respiratory equipment",
    },
    {
      value: "",
      name: "3",
      label: "Eye protection",
    },
    {
      value: "",
      name: "4",
      label: "Rescue line & 5 point harness",
    },
    {
      value: "",
      name: "5",
      label: "Special Clothes",
    },
    {
      value: "",
      name: "6",
      label: "Gloves",
    },
  ];

  const classifiedWherethrid = [
    {
      value: "",
      name: "1",
      label: "Purged with air",
    },
    {
      value: "",
      name: "2",
      label: "Flushed with water",
    },
    {
      value: "",
      name: "3",
      label: "Purged of inert gas",
    },
  ];

  const classifiedWheresecound = [
    {
      value: "",
      name: "1",
      label: "Access/egress ladder",
    },
    {
      value: "",
      name: "2",
      label: "Scaffold",
    },
    {
      value: "",
      name: "3",
      label: "Crane",
    },
    {
      value: "",
      name: "4",
      label: "Method of communication (intrinsically safe)",
    },
    {
      value: "",
      name: "5",
      label: "Continuous ventilation",
    },
    {
      value: "",
      name: "6",
      label: "Fire extinguisher",
    },
    {
      value: "",
      name: "7",
      label: "Safety officer near manhole",
    },
    {
      value: "",
      name: "8",
      label: "Emergency plan understood for all",
    },
    {
      value: "",
      name: "9",
      label: "Emergency plans practiced",
    },
    {
      value: "",
      name: "10",
      label: "Emergency recovery equipment available",
    },
    {
      value: "",
      name: "11",
      label: "Warning signs understood by all",
    },
  ];

  const [getHazard, setHazard] = useState({
    o: "",
    c: "",
    h: "",
    lel: "",
    date: null,
    appro: "",
  });
  const [editHazard, setEditHazard] = useState({ bol: false, i: null });
  const [hazardData, setHazardData] = useState([]);

  const handleHazard = () => {
    if (editHazard.bol) {
      let updateHazard = [...hazardData];
      updateHazard[editHazard.i] = getHazard;
      setHazardData(updateHazard);
      setEditHazard({ bol: false, i: null });
      setHazard({
        o: "",
        c: "",
        h: "",
        lel: "",
        date: null,
        appro: "",
      });
    } else {
      setHazardData([...hazardData, getHazard]);
      setHazard({
        o: "",
        c: "",
        h: "",
        lel: "",
        date: null,
        appro: "",
      });
    }
  };

  const hazardColumns = [
    {
      Header: "O2",
      accessor: "o",
    },

    {
      Header: "CO2",
      accessor: "c",
    },
    {
      Header: "H2S",
      accessor: "h",
    },
    {
      Header: "LEL",
      accessor: "lel",
    },
    {
      Header: "Date/Time",
      accessor: "date",
      Cell: ({ value }) => {
        return dayjs(value).format("DD/MM/YYYY HH:mm");
      },
    },
    {
      Header: "AGT Approval",
      accessor: "appro",
    },
    {
      accessor: "id",
      Cell: ({ cell }) => {
        return (
          <Box
            sx={{
              whiteSpace: "nowrap",
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                setEditHazard({ bol: true, i: cell.row.id });
                setHazard(cell.row.original);
              }}
            >
              <EditOutlined sx={{ color: commonCss.red }} />
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const handleMeasure = (value, dataDetail, name) => {};
  const measureColumns = [
    {
      accessor: "rowName",
      Cell: ({ cell }) => {
        return (
          <Typography sx={{ fontSize: "15px", fontWeight: 700 }}>
            {cell.value}
          </Typography>
        );
      },
    },

    {
      Header: "Method of Measurement",
      accessor: "MOM",
      Cell: ({ cell }) => {
        const [value, setValue] = useState(cell.value);
        return (
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            value={value}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: commonCss.inputBackgroundColor,
                borderRadius: commonCss.inputBorderRadius,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: commonCss.inputBorderColor,
              },
            }}
            onChange={(e) => {
              setValue(e.target.value);
              cell.row.original.MOM = e.target.value;
            }}
            onBlur={(e) =>
              handleMeasure(e.target.value, cell.row.original, "MOM")
            }
          />
        );
      },
    },
    {
      Header: "Result",
      accessor: "result",
      Cell: ({ cell }) => {
        const [value, setValue] = useState(cell.value);
        return (
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            value={value}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: commonCss.inputBackgroundColor,
                borderRadius: commonCss.inputBorderRadius,
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: commonCss.inputBorderColor,
              },
            }}
            onChange={(e) => {
              setValue(e.target.value);
              cell.row.original.result = e.target.value;
            }}
            onBlur={(e) =>
              handleMeasure(e.target.value, cell.row.original, "result")
            }
          />
        );
      },
    },
  ];

  return (
    <Grid2 container size={12} spacing={"1rem"}>
      <Grid2 container size={12} alignItems={"center"}>
        <Grid2 container size={4}>
          <Box
            sx={{
              backgroundColor: commonCss.shadeGreen,
              display: "flex",
              alignItems: "cente",
              p: 1,
              cursor: "pointer",
            }}
            onClick={handleCloseClick}
          >
            <ArrowBack color={commonCss.green} />
          </Box>
        </Grid2>
        <Grid2 container size={4} justifyContent={"center"}>
          <Typography variant="h5" fontWeight="bold" color="white">
            Confined Space Certificate
          </Typography>
        </Grid2>
        <Grid2 container size={4} justifyContent={"flex-end"}>
          <Box
            sx={{
              backgroundColor: commonCss.shadeGreen,
              p: 1,
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              Permit To Work No. <span style={{ color: "#e53e3e" }}>2701</span>
            </Typography>
          </Box>
        </Grid2>
        <Divider
          sx={{ borderColor: commonCss.red, borderWidth: 1, width: "100%" }}
        />
      </Grid2>
      <Grid2 container size={12} alignItems={"center"}>
        <Typography color="white" variant="body2">
          (To be completed only by authorised persons)
        </Typography>
      </Grid2>
      <Grid2 container size={12}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"0.6rem"}>
            <Grid2 sx={{ mb: 2 }} size={{ xs: 12, md: 12 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Activity
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
          </Grid2>
          <Grid2 container size={12} spacing={"0.6rem"}>
            <Grid2 sx={{ mb: 2 }}>
              <Typography
                variant="body2"
                fontWeight={commonCss.mainLabelHeading}
                color={commonCss.red}
              >
                HAZARDS
              </Typography>
            </Grid2>
          </Grid2>
          <Grid2 container size={12} spacing={"0.6rem"}>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Fire & Explosion
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Asphyxiation
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Poisioning
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 12 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Others
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={12}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"0.6rem"}>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.mainLabelHeading}
                color={commonCss.red}
              >
                DESCRIBE THE CONFINED SPACE
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Location
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Access/egress
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Number of open entrances
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Diameter
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Height
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Other entrances
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Description
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Contents
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  How were the residues removed from confined space?
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Is this confined space now free of residues?
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={12}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"0.6rem"}>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  AGT Name
                </Typography>
              </InputLabel>
              <Select
                size="small"
                defaultValue=""
                displayEmpty
                inputProps={{ "aria-label": "Technician" }}
                sx={{
                  borderRadius: "13px",
                  backgroundColor: "white",
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "747373",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
                fullWidth
              >
                <MenuItem value="tech1">Name 1</MenuItem>
                <MenuItem value="tech2">Name 2</MenuItem>
                <MenuItem value="tech3">Name 3</MenuItem>
              </Select>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date and Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: commonCss.inputBackgroundColor,
                        borderRadius: commonCss.inputBorderRadius,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={12}>
        <Grid2 size={{ xs: 12, md: 6 }}>
          <Grid2 container direction="column" spacing={2}>
            <Grid2 item>
              <Paper
                elevation={3}
                sx={{
                  borderRadius: commonCss.paperBorderRadius,
                  p: 1.5,
                  background: commonCss.shadeLinearGradient,
                  width: "100%",
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={commonCss.mainLabelHeading}
                  color={commonCss.red}
                >
                  Personal Protective Clothes
                </Typography>
                <FormGroup>
                  {classifiedWhere.map((typeLevel, i) => (
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          name={typeLevel.name}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              color: "#00807F",
                            },
                            "&:checked": {
                              color: "#00807F",
                            },
                          }}
                        />
                      }
                      label={typeLevel.label}
                    />
                  ))}
                </FormGroup>
              </Paper>
            </Grid2>

            <Grid2 item>
              <Paper
                elevation={3}
                sx={{
                  borderRadius: commonCss.paperBorderRadius,
                  p: 1.5,
                  background: commonCss.shadeLinearGradient,
                  width: "100%",
                }}
              >
                <Typography
                  variant="body2"
                  fontWeight={commonCss.mainLabelHeading}
                  color={commonCss.red}
                >
                  How was the confined space cleaned?
                </Typography>
                <FormGroup>
                  {classifiedWherethrid.map((typeLevel, i) => (
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          name={typeLevel.name}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              color: "#00807F",
                            },
                            "&:checked": {
                              color: "#00807F",
                            },
                          }}
                        />
                      }
                      label={typeLevel.label}
                    />
                  ))}
                </FormGroup>
                <InputLabel sx={{ pl: 0.5 }}>
                  <Typography variant="body2" fontWeight="500" color="black">
                    Specify cleansing agent used
                  </Typography>
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  multiline
                  rows={2}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: commonCss.inputBackgroundColor,
                      borderRadius: commonCss.inputBorderRadius,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: commonCss.inputBorderColor,
                    },
                  }}
                />
              </Paper>
            </Grid2>
          </Grid2>
        </Grid2>

        <Grid2 size={{ xs: 12, md: 6 }}>
          <Paper
            elevation={3}
            sx={{
              borderRadius: commonCss.paperBorderRadius,
              p: 1.5,
              background: commonCss.shadeLinearGradient,
              width: "100%",
              height: "100%",
            }}
          >
            <Typography
              variant="body2"
              fontWeight={commonCss.mainLabelHeading}
              color={commonCss.red}
            >
              Equipment, emergency arrangement, etc...,
            </Typography>
            <FormGroup>
              {classifiedWheresecound.map((typeLevel, i) => (
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      name={typeLevel.name}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          color: "#00807F",
                        },
                        "&:checked": {
                          color: "#00807F",
                        },
                      }}
                    />
                  }
                  label={typeLevel.label}
                />
              ))}
            </FormGroup>
          </Paper>
        </Grid2>
      </Grid2>
      <Grid2 container size={12}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 sx={{ pl: 0.5, mb: 2 }}>
            <Typography
              variant="body2"
              fontWeight={commonCss.mainLabelHeading}
              color={commonCss.red}
            >
              Measurements before entering
            </Typography>
          </Grid2>
          <Grid2 size={12} mb={2}>
            <NormalTableWithRadius
              data={measureData}
              columns={measureColumns}
            />
          </Grid2>
          <Grid2 container direction="row" alignItems="center" spacing={2}>
            <InputLabel>
              <Typography variant="body2" fontWeight="500" color="black">
                Testing Frequency
              </Typography>
            </InputLabel>
            <TextField
              variant="outlined"
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: commonCss.inputBackgroundColor,
                  borderRadius: commonCss.inputBorderRadius,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: commonCss.inputBorderColor,
                },
                width: "85%",
              }}
            />
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={12}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"0.6rem"}>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.mainLabelHeading}
                color={commonCss.red}
              >
                Gas test performed
              </Typography>
            </Grid2>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.normalSentenceWeight}
                color={commonCss.normalSenternceColor}
              >
                I confirm that I have tested the confined space for gas and/or
                oxygen and that the result are within the specified acceptance
                levels, and the measured concertation of explosive
                gasses/vapours is zero % LEL
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  O2
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={getHazard.o}
                onChange={(e) => {
                  setHazard({
                    ...getHazard,
                    o: e.target.value,
                  });
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  CO2
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={getHazard.c}
                onChange={(e) => {
                  setHazard({
                    ...getHazard,
                    c: e.target.value,
                  });
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  H2S
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={getHazard.h}
                onChange={(e) => {
                  setHazard({
                    ...getHazard,
                    h: e.target.value,
                  });
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  LEL
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={getHazard.lel}
                onChange={(e) => {
                  setHazard({
                    ...getHazard,
                    lel: e.target.value,
                  });
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>

            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    value={getHazard.date ? dayjs(getHazard.date) : null}
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    onChange={(newValue) => {
                      if (newValue !== null) {
                        if (`${newValue.$d}` !== "Invalid Date") {
                          const formattedDate = moment(newValue.$d).format(
                            "YYYY-MM-DD HH:mm"
                          );
                          console.log(formattedDate);
                          setHazard({
                            ...getHazard,
                            date: formattedDate,
                          });
                        }
                      }
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: commonCss.inputBackgroundColor,
                        borderRadius: commonCss.inputBorderRadius,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <InputLabel sx={{ pl: commonCss.labelPadding }}>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  AGT Approval
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={getHazard.appro}
                onChange={(e) => {
                  setHazard({
                    ...getHazard,
                    appro: e.target.value,
                  });
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 container size={{ xs: 12 }} justifyContent={"center"}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: commonCss.red,
                  borderRadius: commonCss.buttonBorderRadius,
                  textTransform: "none",
                  padding: "0.6rem 1rem",
                  color: commonCss.buttonTextColor,
                  width: "15vw",
                }}
                onClick={handleHazard}
              >
                Add
              </Button>
            </Grid2>
            <Grid2
              size={{ xs: 12 }}
              sx={{ display: hazardData.length === 0 ? "none" : "default" }}
            >
              <Paper sx={{ background: "transparent" }}>
                <NormalTableWithRadius
                  data={hazardData}
                  columns={hazardColumns}
                />
              </Paper>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={4}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"1rem"}>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.mainLabelHeading}
                color={commonCss.red}
                textAlign={"center"}
              >
                Engineer
              </Typography>
              <Divider
                sx={{
                  borderColor: commonCss.red,
                  borderWidth: 1,
                  width: "100%",
                  mt: 1,
                }}
              />
            </Grid2>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.normalSentenceWeight}
                color={commonCss.normalSenternceColor}
                sx={{ textTransform: "none" }}
              >
                I certify that I have reviewed the proposed work and I am
                satisfied that it is adequately described on the permit to work
                and that the precautions detailed are adequate and clearly
                defined
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Name
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={4}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"1rem"}>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.mainLabelHeading}
                color={commonCss.red}
                textAlign={"center"}
              >
                LPI
              </Typography>
              <Divider
                sx={{
                  borderColor: commonCss.red,
                  borderWidth: 1,
                  width: "100%",
                  mt: 1,
                }}
              />
            </Grid2>
            <Grid2 size={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.normalSentenceWeight}
                color={commonCss.normalSenternceColor}
                sx={{ textTransform: "none" }}
              >
                Confined Space is described in the permit to work is allowed
                between:
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Name
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  From
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Until
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>
      <Grid2 container size={4}>
        <Paper
          elevation={3}
          sx={{
            borderRadius: commonCss.paperBorderRadius,
            p: 1.5,
            background: commonCss.shadeLinearGradient,
            width: "100%",
          }}
        >
          <Grid2 container size={12} spacing={"1rem"}>
            <Grid2 item xs={12}>
              <Typography
                variant="body2"
                fontWeight={commonCss.normalSentenceWeight}
                color={commonCss.normalSenternceColor}
                sx={{ textTransform: "none" }}
              >
                Confined Space certificate is certificate and permit to work are
                withdrawn:
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Name
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Date/Time
                </Typography>
              </InputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoItem>
                  <DateTimePicker
                    ampm={false}
                    format="DD/MM/YYYY HH:mm"
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    slotProps={{
                      textField: {
                        size: "small",
                        variant: "outlined",
                      },
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        borderRadius: commonCss.inputBorderRadius,
                        backgroundColor: commonCss.inputBackgroundColor,
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: commonCss.inputBorderColor,
                      },
                    }}
                    fullWidth
                  />
                </DemoItem>
              </LocalizationProvider>
            </Grid2>
            <Grid2 size={{ xs: 12 }}>
              <InputLabel>
                <Typography
                  variant="body2"
                  fontWeight={commonCss.labelFontWeight}
                  color={commonCss.labelInputColor}
                >
                  Reason
                </Typography>
              </InputLabel>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                multiline
                minRows={2}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: commonCss.inputBackgroundColor,
                    borderRadius: commonCss.inputBorderRadius,
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: commonCss.inputBorderColor,
                  },
                }}
              />
            </Grid2>
          </Grid2>
        </Paper>
      </Grid2>

      <Grid2 container size={12} justifyContent={"flex-end"}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: commonCss.green,
            borderRadius: commonCss.buttonBorderRadius,
            textTransform: "none",
            padding: "0.6rem 1rem",
            color: commonCss.buttonTextColor,
            width: "10vw",
          }}
        >
          Submit
        </Button>
      </Grid2>
    </Grid2>
  );
};

export default ConfinedSpaceCertificate;
